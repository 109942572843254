import React, { useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { GET_ROLES_FN, RULES_FN } from '../../utils/consts';
import { isUserAdmin } from '../../utils/helpers';
import globalController from '../../mobx/GlobalController';
import { observer } from 'mobx-react-lite';
import { langs } from '../../utils/lang';

const { Option } = Select;

interface Props {
  open: boolean | any;
  onCreate: (values: any) => void;
  onEdit?: (values: any) => void;
  onCancel: () => void;
}

const languages = langs.EmployeeModal;

const EmployeeModal = observer(({ open, onEdit, onCreate, onCancel }: Props) => {
  const [form] = Form.useForm();
  const user = globalController.getUser();
  const lang = globalController.getLang();
  const ROLES = GET_ROLES_FN(lang);
  const RULES = RULES_FN(lang);

  const handleCreateOrEdit = () => {
    form
      .validateFields()
      .then((values) => {
        values.phone = open?.phone || '';
        // if (!values.phone) delete values.phone;
        if (open === true) {
          onCreate(values);
        } else if (typeof open === 'object' && onEdit) {
          values.id = open.id;
          onEdit(values);
        }
      })
      .catch((errorInfo) => {
        console.log('Validation failed:', errorInfo);
      });
  };

  useEffect(() => {
    if (typeof open === 'object') {
      form.setFieldsValue({
        name: open?.name || '',
        email: open?.email || '',
        role: open?.roles?.[0] || '',
      });
    } else if (open === true) {
      form.resetFields();
    }
  }, [form, open]);

  return (
    <Modal
      centered
      open={open}
      title={open !== true ? languages.title[lang] : languages.newEmployee[lang]}
      okText={open !== true ? languages.save[lang] : languages.create[lang]}
      cancelText={languages.cancel[lang]}
      onCancel={onCancel}
      onOk={handleCreateOrEdit}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="name" label={languages.nameLabel[lang]} rules={RULES.NAME}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label={languages.emailLabel[lang]} rules={RULES.EMAIL}>
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="role"
          label={languages.roleLabel[lang]}
          rules={[{ required: true, message: languages.rolePlaceholder[lang] }]}
          className="w-1/2"
        >
          <Select disabled={!isUserAdmin(user?.roles)}>
            {ROLES.map((el) => (
              <Option value={el.name} key={el.name}>
                {el.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default EmployeeModal;
