import { Radio } from 'antd';
import { Row } from '../../components/PageElements';
import { COMPANY_TYPES } from '../../utils/consts';
import { Line } from '../Clients/ModalStatus';
import { observer } from 'mobx-react-lite';
import globalController from '../../mobx/GlobalController';
import { langs } from '../../utils/lang';

const languages = langs.showStatus;

export const showStatus = observer(({ dataClient, dataAccounts, noBlock }: any) => {
  const lang = globalController.getLang();
  const type = dataClient?.company?.type || dataClient?.type;
  const name = dataClient?.company?.name || dataClient?.name;
  const inn = dataClient?.company?.inn || dataClient?.inn;
  const kpp = dataClient?.company?.kpp || dataClient?.kpp;
  const ogrn = dataClient?.company?.ogrn || dataClient?.ogrn;
  const director = dataClient?.company?.director || dataClient?.director;
  const address = dataClient?.company?.address || dataClient?.address;

  const passport = dataClient?.company?.passport || dataClient?.passport;
  const phone = dataClient?.company?.phone || dataClient?.phone;
  const email = dataClient?.company?.email || dataClient?.email;
  const position = dataClient?.company?.position || dataClient?.position;

  if (!dataClient || !type) return null;

  return (
    <>
      <Radio.Group
        value={COMPANY_TYPES.findIndex((ttype) => ttype === type)}
        className="flex items-center gap-4 mb-4"
      >
        <Radio
          value={0}
          checked={type === COMPANY_TYPES[0]}
          disabled={type !== COMPANY_TYPES[0]}
          className="!min-w-fit"
        >
          {languages.individual[lang]}
        </Radio>
        <Radio
          value={1}
          checked={type === COMPANY_TYPES[1]}
          disabled={type !== COMPANY_TYPES[1]}
          className="!min-w-fit"
        >
          {languages.soleProprietor[lang]}
        </Radio>
        <Radio
          value={2}
          checked={type === COMPANY_TYPES[2]}
          disabled={type !== COMPANY_TYPES[2]}
          className="!min-w-fit"
        >
          {languages.legalEntity[lang]}
        </Radio>
      </Radio.Group>

      {type === COMPANY_TYPES[0] ? (
        <div className="flex flex-col gap-3">
          <Row name={languages.passport[lang]} value={passport} />
          <Row name={languages.phone[lang]} value={phone} />
          <Row name={languages.email[lang]} value={email} />
          <Row name={languages.position[lang]} value={position} />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-2 mt-3">
            <Line data={name} name={languages.companyName[lang]} />
            <Line data={inn} name={languages.inn[lang]} />
            <Line data={kpp} name={languages.kpp[lang]} />
            <Line data={ogrn} name={languages.ogrn[lang]} />
            <Line data={director} name={languages.director[lang]} />
            <Line data={address} name={languages.legalAddress[lang]} />
          </div>
          {dataAccounts?.items?.length > 0 && (
            <div className="flex w-full justify-between">
              <h3 className="mt-6 mb-[10px]">{languages.bankDetails[lang]}</h3>
            </div>
          )}
          {dataAccounts?.items?.length > 0 && <p>{languages.contractAccount[lang]}</p>}
          <ul className="w-full flex flex-col gap-2 mt-2">
            {(dataAccounts?.items || [])?.map((el: any) => (
              <li
                key={JSON.stringify(el)}
                className="flex w-full text-sm justify-between items-center"
              >
                <button className="flex disabled:opacity-40" disabled={!el.isDefaultAccount}>
                  <Radio checked={el.isDefaultAccount} disabled={!el.isDefaultAccount} />
                  {el.bankName}
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
});
