import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Input } from 'antd';
import { toast } from 'react-toastify';
import { LockFilledIcon } from '../../components/Icons/LockFilled';
import { useLocation, useNavigate } from 'react-router-dom';
import { authResetPasswordConfirm } from '../../utils/httpServices/global';
import { validatePassword } from '../../utils/helpers';
import { langs } from '../../utils/lang';
import globalController from '../../mobx/GlobalController';

const languages = langs.NewPass;

const NewPass = observer(() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const lang = globalController.getLang();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const isRecovery = pathname.includes('reset');

  const [form] = Form.useForm();
  const [errors, setErrors] = useState<any>({});
  const [validation, setValidation] = useState<any>({});
  const [formValues, setFormValues] = useState({ password: '', password2: '' });

  const resetErrors = () => {
    setErrors({});
    setValidation({});
  };

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then(async () => {
        if (formValues.password !== formValues.password2) {
          toast(languages.passwordsDoNotMatch[lang], { type: 'error' });
          setErrors({ password: '', password2: languages.passwordsDoNotMatch[lang] });
          setValidation({ validateStatus: 'error' });
          return;
        } else {
          if (code) {
            const res = await authResetPasswordConfirm({ code, password: formValues.password });
            if (res?.error) {
              const msg =
                res.error.response?.data?.violations[0]?.message ||
                res.error?.response?.data?.message ||
                res.error.message;
              setErrors({ password: ' ', password2: msg });
              setValidation({ validateStatus: 'error' });
              return;
            }
          }

          toast(languages.passwordSavedSuccess[lang], { type: 'success' });
          resetErrors();
          setTimeout(() => {
            navigate('/');
          }, 2500);
        }
      })
      .catch((errorInfo) => {
        const newErrors: any = {};
        errorInfo.errorFields.forEach((field: any) => {
          newErrors[field.name[0]] = field.errors[0];
        });
        setErrors(newErrors);
      });
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    setFormValues(allValues);
    resetErrors();
  };

  return (
    <div className="my-8 mx-[50px] flex flex-col items-center">
      <div className="text-center mb-4">
        <LockFilledIcon className="mx-auto" />
        <h1 className="mt-6 mb-1 text-[24px] mx-auto">
          {isRecovery ? languages.pageTitleRecovery[lang] : languages.pageTitleRegistration[lang]}
        </h1>
      </div>

      <Form
        form={form}
        className="flex flex-col md:w-1/4 sm:w-1/3 w-full"
        name="new-pass-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="password"
          rules={[{ validator: validatePassword }]}
          help={errors.password}
          {...validation}
        >
          <Input.Password placeholder={languages.placeholderNewPassword[lang]} allowClear />
        </Form.Item>

        <Form.Item
          name="password2"
          rules={[{ validator: validatePassword }]}
          help={errors.password2}
          {...validation}
        >
          <Input.Password placeholder={languages.placeholderRepeatPassword[lang]} allowClear />
        </Form.Item>

        <div className="text-xs mt-4">
          <sup>*</sup> {langs.Helpers.passwordRequirements[lang]}
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full mt-6"
            disabled={!formValues.password || !formValues.password2}
          >
            {languages.buttonSave[lang]}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});

export default NewPass;
