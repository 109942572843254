import { Divider, Modal, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';

import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  frontmatterPlugin,
  headingsPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  toolbarPlugin,
  UndoRedo,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { observer } from 'mobx-react-lite';
import { Block } from '../../components/PageElements';
import globalController from '../../mobx/GlobalController';
import { isUserAdmin } from '../../utils/helpers';
import { createMD, getMD } from '../../utils/httpServices/global';
import './editor.css';
import SettingsItem from './settingsItem';
import { LICENSE_PATH, POLICY_PATH, AGREEMENT_PATH } from '../../utils/routes_consts';
import { useMatchMedia } from '@wojtekmaj/react-hooks';
import { langs } from '../../utils/lang';

const languages = langs.Settings;

const Settings = observer(() => {
  const isMobile = useMatchMedia('(max-width: 768px)');
  const user = globalController.getUser();
  const lang = globalController.getLang();
  const [markdownValue, setMarkdownValue] = useState<string>('');
  const mdxEditorRef = useRef<MDXEditorMethods>(null);
  const [editModal, setEditModal] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState(false);
  const [MDTexts, setMDTexts] = useState({ policyText: '', licenseText: '', conditionsText: '' });

  const viewDocument = (url: string) => window.open(url, '_blank');

  const fetchMDContent = async () => {
    setFetching(true);
    const [licenseText, policyText, conditionsText] = await Promise.all([
      getMDContent('license_agreement'),
      getMDContent('personal_data_processing_policy'),
      getMDContent('company_policy'),
    ]).finally(() => setFetching(false));
    setMDTexts({ licenseText, conditionsText, policyText });
  };

  useEffect(() => {
    fetchMDContent();
  }, []);

  const onLoad = () => {
    setLoading(true);
    createMD(editModal, markdownValue)
      .then((response) => {
        if (!response) {
          setEditModal('');
          fetchMDContent();
        }
      })
      .finally(() => setLoading(false));
  };

  const getMDContent = async (contentType: string) => {
    const response = await getMD(contentType).then((response) => response.content);
    return response;
  };

  return (
    <div className="my-4 mx-[50px] flex flex-col">
      <div className="w-full flex justify-between mb-4">
        <h1 className="text-[20px]">{languages.title[lang]}</h1>
      </div>
      <Spin spinning={fetching}>
        <Block>
          <h3>{languages.contentManagement[lang]}</h3>
          <div className="flex flex-col mt-7 w-full">
            <SettingsItem
              hasContent={!!MDTexts.licenseText}
              name={languages.termsOfUse[lang]}
              onLoad={() => {
                setMarkdownValue(MDTexts.licenseText);
                mdxEditorRef.current?.setMarkdown(MDTexts.licenseText);
                setEditModal('license_agreement');
              }}
              onView={() => viewDocument(LICENSE_PATH)}
              canChange={isUserAdmin(user?.roles)}
            />
            <Divider className="my-3" />
            <SettingsItem
              hasContent={!!MDTexts.policyText}
              name={languages.userAgreement[lang]}
              onView={() => viewDocument(AGREEMENT_PATH)}
              onLoad={() => {
                setMarkdownValue(MDTexts.policyText);
                mdxEditorRef.current?.setMarkdown(MDTexts.policyText);
                setEditModal('personal_data_processing_policy');
              }}
              canChange={isUserAdmin(user?.roles)}
            />
            <Divider className="my-3" />
            <SettingsItem
              canChange={isUserAdmin(user?.roles)}
              name={languages.privacyPolicy[lang]}
              hasContent={!!MDTexts.conditionsText}
              onView={() => viewDocument(POLICY_PATH)}
              onLoad={() => {
                setMarkdownValue(MDTexts.conditionsText);
                mdxEditorRef.current?.setMarkdown(MDTexts.conditionsText);
                setEditModal('company_policy');
              }}
            />
          </div>
        </Block>
      </Spin>
      <Modal
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading }}
        onCancel={() => setEditModal('')}
        closable={false}
        open={!!editModal}
        onOk={onLoad}
        width={isMobile ? '100%' : '80%'}
      >
        <div className="w-full pt-10 editor-modal">
          <MDXEditor
            ref={mdxEditorRef}
            markdown={markdownValue}
            placeholder={languages.modalPlaceholder[lang]}
            onChange={(e) => setMarkdownValue(e)}
            plugins={[
              frontmatterPlugin(),
              headingsPlugin(),
              listsPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <UndoRedo />
                    <BoldItalicUnderlineToggles />
                    <ListsToggle />
                    <BlockTypeSelect />
                  </>
                ),
              }),
            ]}
          />
        </div>
      </Modal>
    </div>
  );
});

export default Settings;
