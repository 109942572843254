import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

import { deleteDoc, getDoc, getDocs, setStateDoc } from '../../utils/httpServices/global';
import { Button, Popconfirm, Tabs, Tag } from 'antd';
import {
  DeleteOutlined,
  PrinterOutlined,
  StopOutlined,
  SendOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import { Block } from '../../components/PageElements';
import Table from '../../components/Table';
import Spinner from '../../components/Spinner';
import { BASE_URL, DOC_TYPES_FN } from '../../utils/consts';
import { UPLOADS_PATH } from '../../utils/routes_consts';
import globalController from '../../mobx/GlobalController';
import { langs } from '../../utils/lang';

const PER_PAGE = 10;
const PER_PAGE_SIGNED = 4;

const languages = langs.Docs;

const Docs = observer(() => {
  const lang = globalController.getLang();
  const DOC_TYPES = DOC_TYPES_FN(lang);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string>('uploaded');
  const [page, setPage] = useState(1);

  const {
    data: docs,
    isLoading: isLoading0,
    refetch,
  } = useQuery(['docs', page], () => getDocs({ page }));
  const { data: docsSigned, isLoading: isLoading1 } = useQuery(['docs-signed', page], () =>
    getDocs({ page, perPage: 4, state: 'signed' }),
  );
  const isLoading = isLoading0 || isLoading1;

  const columns = useMemo(
    () => [
      {
        title: languages.title[lang],
        dataIndex: 'name',
        render: (name: string, row: any) => (
          <div
            className="underline text-[#1677FF] cursor-pointer max-w-[40vw]"
            role="link"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/doc/${row.id}`);
            }}
          >
            {name?.replace(/.pdf/i, '') || ''}
          </div>
        ),
      },
      {
        title: languages.type[lang],
        dataIndex: 'type',
        render: (type: string) => <>{DOC_TYPES.find((el) => el.type === type)?.name || ''}</>,
      },
      {
        title: languages.manager[lang],
        dataIndex: 'customer',
        render: (customer: any) => <>{customer?.manager?.name || ''}</>,
      },
      {
        title: languages.recipient[lang],
        dataIndex: 'customer',
        render: (customer: any) => <>{customer?.name || ''}</>,
      },
      {
        title: languages.status[lang],
        dataIndex: 'state',
        render: (state: string) => (
          <>
            {state === 'draft' && (
              <Tag color="rgba(0,0,0,.04)" style={{ border: 'rgba(0, 0, 0, 0.15) 1px solid' }}>
                <p style={{ color: 'rgba(0, 0, 0, 0.88)' }}>{languages.draft[lang]}</p>
              </Tag>
            )}
            {state === 'sent' && (
              <Tag color="#E6F4FF" style={{ border: '#91CAFF 1px solid' }}>
                <p style={{ color: '#1677FF' }}>{languages.sent[lang]}</p>
              </Tag>
            )}
            {state === 'signed' && (
              <Tag color="#F6FFED" style={{ border: '#B7EB8F 1px solid' }}>
                <p style={{ color: '#52C41A' }}>{languages.signed[lang]}</p>
              </Tag>
            )}
            {state === 'revoked' && (
              <Tag style={{ border: '#FFBB96 1px solid' }} color="#FFF2E8">
                <p style={{ color: '#FA541C' }}>{languages.revoked[lang]}</p>
              </Tag>
            )}
          </>
        ),
      },
      {
        title: languages.actions[lang],
        dataIndex: 'id',
        render: (id: number | string, row: any) => (
          <div className="flex gap-5 items-center w-full" onClick={(e) => e.stopPropagation()}>
            {/* {row.state === 'revoked' && (
              <div className="w-full h-6" onClick={(e) => e.preventDefault()} />
            )} */}
            {row.state === 'draft' && (
              <button
                onClick={() => {
                  if (row?.id && row?.customer?.id) {
                    setStateDoc({ id: row.id, state: 'sent' }).then(() => {
                      refetch();
                    });
                  } else {
                    toast(languages.selectClient[lang], { type: 'error' });
                  }
                }}
                title={languages.send[lang]}
              >
                <SendOutlined style={{ fontSize: 24, color: '#1677FF' }} />
              </button>
            )}
            <button
              title={languages.print[lang]}
              onClick={() => {
                navigate(`/doc/${row.id}?print`, { replace: false });
              }}
            >
              <PrinterOutlined style={{ fontSize: 24, color: '#1677FF' }} />
            </button>
            <button
              title={languages.download[lang]}
              onClick={() => {
                getDoc(row.id)
                  .then((res) => {
                    if (!res || res.error) return;
                    const link = document.createElement('a');
                    link.href = `${BASE_URL}${res.signedLink || res.link}`;
                    link.download = res.name;
                    link.click();
                    link.remove();
                  })
                  .catch((error) => console.error('ERROR:', error));
              }}
            >
              <DownloadOutlined style={{ fontSize: 24, color: '#1677FF' }} />
            </button>

            {row.state === 'sent' && (
              <Popconfirm
                title={languages.confirm_revoke[lang]}
                onConfirm={() => {
                  setStateDoc({ id: row.id, state: 'revoked' }).then(() => {
                    refetch();
                  });
                }}
                okText={languages.revoke[lang]}
                cancelText={langs.Doc.cancel[lang]}
                description={<div className="my-2">{languages.docDisappear[lang]}</div>}
              >
                <button title={languages.revoke[lang]}>
                  <StopOutlined style={{ fontSize: 24, color: '#FF4D4F' }} />
                </button>
              </Popconfirm>
            )}

            {row.state === 'draft' && (
              <Popconfirm
                title={languages.confirm_delete[lang]}
                onConfirm={() => {
                  deleteDoc(row.id).then(() => refetch());
                }}
                okText={languages.delete[lang]}
                cancelText={langs.Doc.cancel[lang]}
              >
                <button title={languages.delete[lang]}>
                  <DeleteOutlined style={{ fontSize: 24, color: '#FF4D4F' }} />
                </button>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ],
    [navigate, refetch, lang],
  );

  const docsData = activeTab === 'uploaded' ? docs : docsSigned;
  const pagination = useMemo(
    () => ({
      hideOnSinglePage: true,
      showSizeChanger: false,
      pageSize:
        docsData?.pagination?.perPage || activeTab === 'uploaded' ? PER_PAGE : PER_PAGE_SIGNED,
      current: docsData?.pagination?.page || 1,
      total: docsData?.pagination?.totalCount || 0,
      onChange: (page: number) => setPage(page),
    }),
    [activeTab, docsData],
  );

  const showDocs = useCallback(
    () => (
      <div className="flex flex-col w-full items-center px-5 py-10 gap-6">
        {(activeTab === 'uploaded' && docs?.items.length) ||
        (activeTab !== 'uploaded' && docsSigned?.items.length) ? (
          <div className="w-full">
            <div className="w-full flex items-center justify-between">
              <h2>{languages.uploaded_docs[lang]}</h2>
              <Button type="primary" className="w-fit" onClick={() => navigate(UPLOADS_PATH)}>
                {languages.uploadDoc[lang]}
              </Button>
            </div>
            <Block className="mt-5">
              <Table
                loading={isLoading || isLoading0}
                rowKey="id"
                dataSource={docsData?.items}
                columns={columns}
                pagination={pagination}
                onRow={() => ({
                  onClick: (e: any) => {
                    e?.stopPropagation();
                    // navigate(`/doc/${record.id}`);
                  },
                })}
              />
            </Block>
          </div>
        ) : (
          <>
            <div
              className="bg-no-repeat bg-contain w-1/5 aspect-[251/294]"
              style={{ backgroundImage: 'url(/images/no-docs.svg)' }}
            />
            <div className="flex flex-col items-center">
              <h3 className="text-[24px]">{languages.no_docs[lang]}</h3>
              {activeTab === 'uploaded' && (
                <p className="opacity-45">{languages.can_upload[lang]}</p>
              )}
            </div>
            {activeTab === 'uploaded' && (
              <Button type="primary" className="w-fit" onClick={() => navigate(UPLOADS_PATH)}>
                {languages.upload_pdf[lang]}
              </Button>
            )}
          </>
        )}
      </div>
    ),
    [
      activeTab,
      columns,
      docs,
      docsData,
      docsSigned,
      isLoading,
      isLoading0,
      navigate,
      pagination,
      lang,
    ],
  );

  const items = [
    {
      key: 'uploaded',
      label: languages.uploaded[lang],
      children: showDocs(),
    },
    {
      key: 'signed',
      label: languages.signed[lang],
      children: showDocs(),
    },
  ];

  useEffect(() => {
    if (page !== 1) setPage(1);
  }, [activeTab]);

  if (isLoading) return <Spinner fullW className="!mt-[10vh]" sizePx={60} />;

  return (
    <div className="mt-8 mb-4 mx-[50px] flex flex-col">
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={(v) => setActiveTab(v)}
        indicator={{ size: 0 }}
        items={items}
      />
    </div>
  );
});

export default Docs;
