import React, { useEffect, useMemo, useState } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  createCompany,
  deleteCompany,
  editCompany,
  getCompanies,
} from '../../utils/httpServices/global';
import Table from '../../components/Table';
import { Form, Input, Modal, Popconfirm, Button, Radio } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { COMPANY_TYPES, COMPANY_TYPES_NAMES_FN, RULES_FN } from '../../utils/consts';
import { showStatus } from '../Doc/utils';
import { Block } from '../../components/PageElements';
import { isUserAdmin, isUserSupervisor } from '../../utils/helpers';
import globalController from '../../mobx/GlobalController';
import { CLIENTS_PATH } from '../../utils/routes_consts';
import { observer } from 'mobx-react-lite';
import { langs } from '../../utils/lang';

const perPage = 10;
const languages = langs.DB;

const DB = observer(() => {
  const user = globalController.getUser();
  const lang = globalController.getLang();
  const COMPANY_TYPES_NAMES = COMPANY_TYPES_NAMES_FN(lang);
  const RULES = RULES_FN(lang);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchStr, setSearchStr] = useState('');
  const [isShowModal, setIsShowModal] = useState<any>(false);
  const [isEdit, setIsEdit] = useState<any>(false);
  const [selectedValue, setSelectedValue] = useState(0);

  const { data, isLoading, refetch } = useQuery(['companies', page, searchStr], () =>
    getCompanies({ page, perPage }),
  );

  const pagination = useMemo(
    () => ({
      hideOnSinglePage: true,
      showSizeChanger: false,
      pageSize: data?.pagination?.perPage || perPage,
      current: data?.pagination?.page || 1,
      total: data?.pagination?.totalCount || 0,
      onChange: (page: number) => setPage(page),
    }),
    [data],
  );

  const columns = useMemo(
    () => [
      {
        title: languages.columns.organizationName[lang],
        dataIndex: 'name',
      },
      {
        title: languages.columns.creationDate[lang],
        dataIndex: 'createdAt',
        render: (date: string) => <>{date ? new Date(date).toLocaleDateString() : ''}</>,
      },
      {
        title: languages.columns.actions[lang],
        dataIndex: 'id',
        render: (id: any, row: any) => (
          <div
            className="flex gap-4 items-center"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <EditOutlined
              role="button"
              tabIndex={0}
              style={{ fontSize: 24, color: '#1677FF' }}
              onClick={() => {
                setIsShowModal(row);
                setIsEdit(true);
              }}
              title={languages.edit[lang]}
            />

            {(isUserAdmin(user?.roles) || isUserSupervisor(user?.roles)) && (
              <Popconfirm
                title={languages.confirmDelete[lang]}
                onConfirm={() => {
                  deleteCompany(id).then(() => refetch());
                }}
                okText={languages.delete[lang]}
                cancelText={langs.EmployeeModal.cancel[lang]}
              >
                <button title="Удалить" type="button">
                  <DeleteOutlined style={{ fontSize: 24, color: '#FF4D4F' }} />
                </button>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ],
    [refetch],
  );

  const onClose = () => setIsShowModal(false);

  const onFinish = (values: any) => {
    const eValues = {
      ...values,
      ...(isShowModal
        ? { type: COMPANY_TYPES[selectedValue] }
        : { id: isShowModal.id, type: isShowModal.type }),
    };

    const action = isShowModal ? createCompany : editCompany;

    action(eValues).then(() => {
      refetch();
      setIsEdit(false);
      setIsShowModal(false);
    });
  };

  useEffect(() => {
    if (typeof isShowModal === 'object') {
      form.setFieldsValue({ ...isShowModal });
    }
  }, [isShowModal]);

  return (
    <>
      <div className="my-4 mx-[50px] flex flex-col">
        <button
          onClick={() => navigate(CLIENTS_PATH)}
          className="opacity-45 w-fit flex gap-1 items-center select-none"
        >
          <HomeOutlined />
          {languages.backButton[lang]}
        </button>

        <h1 className="text-[20px] mt-1">{languages.title[lang]}</h1>
        <p className="mb-4">{languages.description[lang]}</p>
        {data?.items?.length > 0 ? (
          <Table
            loading={isLoading}
            rowKey="id"
            dataSource={data?.items}
            columns={columns}
            pagination={pagination}
            onRow={(record: any) => ({
              onClick: (e: any) => {
                e?.stopPropagation();
                setIsShowModal(record);
              },
            })}
          />
        ) : (
          <Block className="flex flex-col items-center justify-center min-h-[30vh]">
            <h3 className="text-base">{languages.noData.title[lang]}</h3>
            <p className="text-sm opacity-45">{languages.noData.description[lang]}</p>
            <Button
              type="primary"
              onClick={() => {
                setIsShowModal(true);
                setIsEdit(true);
              }}
              className="mt-4"
            >
              {languages.noData.addButton[lang]}
            </Button>
          </Block>
        )}
      </div>

      {!!isShowModal && isEdit && (
        <Modal
          centered
          onCancel={() => {
            form.resetFields();
            setIsShowModal(false);
            setIsEdit(false);
          }}
          open={!!isShowModal && isEdit}
          footer={null}
          maskClosable={false}
          getContainer={false}
          title={languages.modal.title[isShowModal ? 'create' : 'edit'][lang]}
        >
          <Radio.Group
            onChange={(e) => {
              isShowModal === true && setSelectedValue(e.target.value);
            }}
            value={
              isShowModal === true
                ? selectedValue
                : COMPANY_TYPES.findIndex((el) => el === isShowModal?.type)
            }
            className="flex items-center gap-4 my-4 w-full"
          >
            <Radio
              value={0}
              className="!min-w-fit"
              disabled={isShowModal !== true && isShowModal?.type !== COMPANY_TYPES[0]}
            >
              {COMPANY_TYPES_NAMES[COMPANY_TYPES[0]]}
            </Radio>
            <Radio
              value={1}
              className="!min-w-fit"
              disabled={isShowModal !== true && isShowModal?.type !== COMPANY_TYPES[1]}
            >
              {COMPANY_TYPES_NAMES[COMPANY_TYPES[1]]}
            </Radio>
            <Radio
              value={2}
              className="!min-w-fit"
              disabled={isShowModal !== true && isShowModal?.type !== COMPANY_TYPES[2]}
            >
              {COMPANY_TYPES_NAMES[COMPANY_TYPES[2]]}
            </Radio>
          </Radio.Group>
          <Form
            form={form}
            layout="horizontal"
            className="flex flex-col mt-4"
            name="form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            {isShowModal?.type === COMPANY_TYPES[0] ? (
              <>
                <Form.Item label={languages.modal.fields.name[lang]} name="name" rules={RULES.NAME}>
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  label={languages.modal.fields.passport[lang]}
                  name="passport"
                  rules={RULES.PASSPORT}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  label={languages.modal.fields.phone[lang]}
                  name="phone"
                  rules={RULES.PHONE}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  label={languages.modal.fields.email[lang]}
                  name="email"
                  rules={RULES.EMAIL}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  label={languages.modal.fields.position[lang]}
                  name="position"
                  rules={RULES.POSITION}
                >
                  <Input allowClear />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label={languages.modal.fields.companyName[lang]}
                  name="name"
                  rules={[{ required: true, message: languages.enterCompany[lang] }]}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label={languages.modal.fields.inn[lang]}
                  name="inn"
                  rules={RULES.INN}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label={languages.modal.fields.kpp[lang]}
                  name="kpp"
                  rules={RULES.KPP}
                >
                  <Input allowClear />
                </Form.Item>

                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label={languages.modal.fields.ogrn[lang]}
                  name="ogrn"
                  rules={RULES.OGRN}
                >
                  <Input allowClear />
                </Form.Item>

                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label={languages.modal.fields.director[lang]}
                  name="director"
                  rules={RULES.DIRECTOR}
                >
                  <Input allowClear />
                </Form.Item>

                <Form.Item
                  labelCol={{ style: { minWidth: 170 } }}
                  label={languages.modal.fields.address[lang]}
                  name="address"
                  rules={[{ required: true, message: languages.enterAddress[lang] }]}
                >
                  <Input.TextArea allowClear />
                </Form.Item>
              </>
            )}

            <Form.Item className="ml-auto">
              <Button type="primary" htmlType="submit">
                {languages.modal.saveButton[lang]}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}

      {!!isShowModal && !isEdit ? (
        <Modal
          centered
          onCancel={onClose}
          open={!!isShowModal}
          footer={null}
          title={languages.modal.organization[lang]}
        >
          {showStatus({ dataClient: isShowModal })}
          <div className="flex items-center gap-4 w-fit ml-auto mt-4">
            <Button onClick={() => setIsEdit(true)}>{languages.modal.title.edit[lang]}</Button>
            <Button type="primary" onClick={onClose}>
              {languages.modal.closeButton[lang]}
            </Button>
          </div>
        </Modal>
      ) : null}
    </>
  );
});

export default DB;
