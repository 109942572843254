import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { langs } from '../../utils/lang';
import globalController from '../../mobx/GlobalController';

const languages = langs.Page404;

const Page404 = observer(() => {
  const lang = globalController.getLang();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full min-h-[60vh] justify-center items-center">
      <h1>{languages.title[lang]}</h1>
      <Button type="primary" className="mt-8" onClick={() => navigate(-1)}>
        {languages.button[lang]}
      </Button>
    </div>
  );
});

export default Page404;
