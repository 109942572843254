import { DownOutlined } from '@ant-design/icons';

import { Button, Dropdown, Modal } from 'antd';

import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { assignManagerToClient, getClient } from '../../utils/httpServices/global';
import { useQuery } from 'react-query';
import { RQ_OPT } from '../../utils/consts';
import { observer } from 'mobx-react-lite';
import globalController from '../../mobx/GlobalController';
import { langs } from '../../utils/lang';

const languages = langs.ModalManager;

const ModalManager = observer(({ open, data, dataManagers, onClose }: any) => {
  const lang = globalController.getLang();
  const { data: clientData } = useQuery(
    ['client', data?.id || 0],
    () => getClient(data.id),
    RQ_OPT,
  );

  const [manager, setManager] = useState<any>(null);

  const onFinish = () => {
    if (!manager) {
      toast(languages.selectManagerError[lang], { type: 'error' });
      return;
    }
    if (!data?.id) {
      toast(languages.clientDataError[lang], { type: 'error' });
      return;
    }

    assignManagerToClient(data.id, { managerId: manager?.id || 0 }).then((res) => {
      if (res?.manager?.id && res?.manager?.name) {
        toast(languages.managerAssigned[lang](res.manager.name), { type: 'success' });
        onClose();
      }
    });
  };

  const handleSelect = (val: any) => {
    const mng = (dataManagers?.items || []).find((el: any) => +el.id === +val.key);
    setManager(mng || null);
  };

  useEffect(() => {
    if (clientData?.manager?.id) {
      handleSelect({ key: clientData.manager.id });
    }
  }, [clientData]);

  return (
    <Modal centered onCancel={onClose} open={open} footer={null}>
      <h2 className="mb-5">{languages.title[lang]}</h2>
      <div className="w-max mb-6">
        <Dropdown
          menu={{
            items: (dataManagers?.items || []).map((el: any) => ({ label: el.name, key: el.id })),
            onClick: handleSelect,
          }}
          autoFocus
          trigger={['click']}
        >
          <a
            tabIndex={0}
            onClick={(e) => e.preventDefault()}
            className="rounded-[6px] border px-4 py-1"
            style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}
          >
            <div className="inline-flex items-center justify-between w-full">
              {manager?.name || languages.managerList[lang]}
              <DownOutlined />
            </div>
          </a>
        </Dropdown>
      </div>
      <div className="flex justify-end">
        <Button type="primary" onClick={onFinish}>
          {languages.save[lang]}
        </Button>
      </div>
    </Modal>
  );
});

export default ModalManager;
