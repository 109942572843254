import { Lang } from '../../utils/consts';
import { langs } from '../../utils/lang';
import {
  DOCS_PATH,
  CLIENTS_PATH,
  EMPLOYEES_PATH,
  NOTIFICATIONS_PATH,
  SETTINGS_PATH,
  NOTIFY_PATH,
  UPLOAD_INFO_PATH,
  LICENSE_PATH,
  POLICY_PATH,
  AGREEMENT_PATH,
} from '../../utils/routes_consts';

export const getMenuItems = (lang: Lang) => [
  { link: DOCS_PATH, name: langs.Clients.documents[lang], roles: [] },
  { link: CLIENTS_PATH, name: langs.Clients.title[lang], roles: [] },
  {
    link: EMPLOYEES_PATH,
    name: langs.Employees.title[lang],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERVISOR'],
  },
  { link: NOTIFICATIONS_PATH, name: langs.Header.notifications[lang], roles: [] },
  { link: SETTINGS_PATH, name: '', roles: [] },
  { link: NOTIFY_PATH, name: '', roles: [] },
  { link: UPLOAD_INFO_PATH, name: '', roles: [] },
  { link: LICENSE_PATH, name: '', roles: [] },
  { link: POLICY_PATH, name: '', roles: [] },
  { link: AGREEMENT_PATH, name: '', roles: [] },
];
