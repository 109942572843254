export const langs = {
  Header: {
    logout: { ru: 'Выйти', en: 'Logout' },
    notifications: {
      ru: 'Уведомления и рассылки',
      en: 'Notifications and mails',
    },
  },
  Helpers: {
    passwordRequirements: {
      ru: 'Пароль должен содержать от 6 до 30 символов, минимум 1 букву, 1 цифру и 1 специальный символ, пробелы не допускаются.',
      en: 'Password must be between 6 and 30 characters long, minimum 1 letter, 1 number and 1 special character, no spaces allowed',
    },
    invalidEmailFormat: {
      ru: 'Неверный формат почты',
      en: 'Invalid email format',
    },
    pleaseEnterPassword: {
      ru: 'Пожалуйста, введите пароль!',
      en: 'Please enter your password!',
    },
    copyError: {
      ru: 'Ошибка при копировании текста',
      en: 'Copying text error',
    },
    formatDateTime: {
      ru: 'Формат даты и времени',
      en: 'Date and time format',
    },
    randomDateWithinLastFiveYears: {
      ru: 'Случайная дата за последние 5 лет',
      en: 'Random date in the last 5 years',
    },
    roleAdmin: {
      ru: 'Администратор',
      en: 'Administrator',
    },
    roleSupervisor: {
      ru: 'Супервайзер',
      en: 'Supervisor',
    },
    roleManager: {
      ru: 'Менеджер',
      en: 'Manager',
    },
  },

  Upload: {
    uploadDocument: {
      ru: 'Загрузка документа',
      en: 'Uploading document',
    },
    uploadFile: {
      ru: 'Загрузить файл',
      en: 'Upload file',
    },
    saveDocument: {
      ru: 'Сохранить документ',
      en: 'Save document',
    },
    selectClient: {
      ru: 'Выберите клиента',
      en: 'Choose client',
    },
    selectDocumentType: {
      ru: 'Выберите тип документа',
      en: 'Choose document type',
    },
    selectParentDocument: {
      ru: 'Выберите документ',
      en: 'Choose document',
    },
    documentTitle: {
      ru: 'Название документа',
      en: 'Document name',
    },
    client: {
      ru: 'Клиент',
      en: 'Client',
    },
    documentType: {
      ru: 'Тип документа',
      en: 'Document type',
    },
    contractReference: {
      ru: 'К договору',
      en: 'To agreement',
    },
    documentNumber: {
      ru: 'Номер',
      en: 'Number',
    },
    setSignaturePlace: {
      ru: 'Установите место для подписи документа',
      en: 'Set place for document signature',
    },
    setStampPlace: {
      ru: 'Место печати',
      en: 'Stamp',
    },
    signaturePlace: {
      ru: 'Место подписи',
      en: 'Signature',
    },
    loadingPdf: {
      ru: 'Загрузка PDF...',
      en: 'Uploading PDF...',
    },
    errorLoadingPdf: {
      ru: 'Ошибка загрузки PDF',
      en: 'Uploading PDF error',
    },
  },
  Settings: {
    title: {
      ru: 'Настройки',
      en: 'Settings',
    },
    contentManagement: {
      ru: 'Управление контентом',
      en: 'Content Management',
    },
    termsOfUse: {
      ru: 'Условия использования',
      en: 'Terms of use',
    },
    userAgreement: {
      ru: 'Пользовательское соглашение',
      en: 'User Agreement',
    },
    privacyPolicy: {
      ru: 'Политика конфиденциальности',
      en: 'Privacy policy',
    },
    modalPlaceholder: {
      ru: 'Введите текст',
      en: 'Enter text',
    },
    modalSave: {
      ru: 'Сохранить',
      en: 'Save',
    },
    modalCancel: {
      ru: 'Отмена',
      en: 'Cancel',
    },
  },

  SettingsItem: {
    upload: {
      ru: 'Загрузить',
      en: 'Upload',
    },
    edit: {
      ru: 'Редактировать',
      en: 'Edit',
    },
    view: {
      ru: 'Просмотреть',
      en: 'View',
    },
  },

  Page404: {
    title: {
      ru: 'Страница не найдена',
      en: 'Page not found',
    },
    button: {
      ru: 'Назад',
      en: 'Back',
    },
  },

  rolesData: {
    categories: {
      clientManagement: {
        ru: 'Управление клиентами',
        en: 'Client management',
      },
      contractManagement: {
        ru: 'Управление договорами клиентов',
        en: 'Clients’ contracts management ',
      },
      employeeManagement: {
        ru: 'Управление сотрудниками',
        en: 'Employee management',
      },
      whatsappMessaging: {
        ru: 'Отправка кастомных сообщений по каналу WhatsApp',
        en: 'Customized messages via WhatsApp',
      },
      settingsManagement: {
        ru: 'Управление настройками',
        en: 'Settings management',
      },
    },
    rights: {
      assignClients: {
        ru: 'Распределение клиентов между менеджерами',
        en: 'Clients assignment to managers',
      },
      verifyClient: {
        ru: 'Верификация клиента',
        en: 'Client verification',
      },
      viewClients: {
        ru: 'Просмотр списка клиентов',
        en: 'Clients list view',
      },
      createClient: {
        ru: 'Создание клиента',
        en: 'Create client',
      },
      editClient: {
        ru: 'Редактирование существующего клиента',
        en: 'Edit existing client',
      },
      deleteClient: {
        ru: 'Удаление клиента',
        en: 'Delete client',
      },
      archiveClient: {
        ru: 'Перевод клиента в архив',
        en: 'Archive client',
      },
      createContract: {
        ru: 'Создание договора',
        en: 'Create agreement',
      },
      editContract: {
        ru: 'Редактирование существующих договоров',
        en: 'Edit existing agreement',
      },
      viewContracts: {
        ru: 'Просмотр существующих договоров',
        en: 'View existing agreements',
      },
      revokeDocuments: {
        ru: 'Отзыв неподписанных документов',
        en: 'Withdrawal unsigned document',
      },
      assignRole: {
        ru: 'Назначение роли',
        en: 'Assign role',
      },
      viewEmployees: {
        ru: 'Просмотр',
        en: 'View',
      },
      deleteEmployee: {
        ru: 'Удаление',
        en: 'Delete',
      },
      editEmployee: {
        ru: 'Редактирование',
        en: 'Edite',
      },
      createEmployee: {
        ru: 'Создание нового сотрудника',
        en: 'Create new employee',
      },
      sendMessageToClients: {
        ru: 'Отправка сообщений определенным клиентам',
        en: 'Send message to specific clients',
      },
      sendMessageToAll: {
        ru: 'Отправка сообщений всем клиентам',
        en: 'Send messages to all clients',
      },
      addCompanyCard: {
        ru: 'Добавление карточки организации',
        en: 'Add company card',
      },
      editCompanyCard: {
        ru: 'Редактирование карточки организации',
        en: 'Edit company card',
      },
      deleteCompanyCard: {
        ru: 'Удаление карточки организации',
        en: 'Delete company card',
      },
      uploadTerms: {
        ru: 'Загрузка Terms & Conditions',
        en: 'Upload Terms & Conditions',
      },
    },
  },
  Roles: {
    back: {
      ru: 'Назад',
      en: 'Back',
    },
    title: {
      ru: 'Роли сотрудников',
      en: 'Employees roles',
    },
    category: {
      ru: 'Категория права',
      en: 'Category of rights',
    },
    right: {
      ru: 'Право',
      en: 'Right',
    },
    superadmin: {
      ru: 'Суперадминистратор',
      en: 'Superadministrator',
    },
    admin: {
      ru: 'Управляющий менеджер',
      en: 'Executive manager',
    },
    financial: {
      ru: 'Финансовый менеджер',
      en: 'Finance manager',
    },
    user: {
      ru: 'Менеджер',
      en: 'Manager',
    },
    client: {
      ru: 'Клиент',
      en: 'Client',
    },
  },
  EmployeeModal: {
    title: {
      ru: 'Редактирование',
      en: 'Edit',
    },
    newEmployee: {
      ru: 'Новый сотрудник',
      en: 'New employee',
    },
    save: {
      ru: 'Сохранить',
      en: 'Save',
    },
    create: {
      ru: 'Создать',
      en: 'Create',
    },
    cancel: {
      ru: 'Отмена',
      en: 'Cancel',
    },
    nameLabel: {
      ru: 'ФИО Сотрудника',
      en: 'Employee name',
    },
    emailLabel: {
      ru: 'Email',
      en: 'Email',
    },
    roleLabel: {
      ru: 'Роль в системе',
      en: 'Role in system',
    },
    rolePlaceholder: {
      ru: 'Пожалуйста, выберите роль',
      en: 'Please choose role',
    },
  },
  Employees: {
    added: {
      ru: 'Сотрудник успешно добавлен',
      en: 'Employee successfully added',
    },
    errorEdit: {
      ru: 'Ошибка редактирования',
      en: 'Editing error',
    },

    title: {
      ru: 'Сотрудники',
      en: 'Employees',
    },
    roles_link: {
      ru: 'Роли сотрудников',
      en: 'Employees roles',
    },
    add_button: {
      ru: 'Добавить',
      en: 'Add',
    },
    columns: {
      name: {
        ru: 'ФИО',
        en: 'Name',
      },
      email: {
        ru: 'Почта',
        en: 'Email',
      },
      role: {
        ru: 'Роль',
        en: 'Role',
      },
      created_at: {
        ru: 'Дата создания',
        en: 'Creation date',
      },
      actions: {
        ru: 'Действия',
        en: 'Actions',
      },
    },
    edit_tooltip: {
      ru: 'Редактировать сотрудника',
      en: 'Edite employee',
    },
    delete_confirm_title: {
      ru: 'Удалить сотрудника',
      en: 'Delete employee',
    },
    delete_confirm_description: {
      ru: 'Данный сотрудник лишится доступа к CRM-системе, но его ФИО сохранится в карточках клиентов до изменения',
      en: 'This employee will lose access to the CRM-system, but his/her name will remain in client cards until its amendment',
    },
    delete_button: {
      ru: 'Удалить',
      en: 'Delete',
    },
    cancel_button: {
      ru: 'Отмена',
      en: 'Cancel',
    },
  },
  Docs: {
    selectClient: {
      ru: 'Необходимо выбрать клиента',
      en: 'Select client',
    },
    docDisappear: {
      ru: 'Документ пропадёт из списка у клиента',
      en: `Document will disappear from the client's list`,
    },
    uploadDoc: {
      ru: 'Загрузить документ',
      en: 'Upload a document',
    },
    can_upload: {
      ru: 'В этот раздел вы можете загружать готовые документы.',
      en: 'You can upload completed documents to this section',
    },

    uploaded_docs: {
      ru: 'Загруженные документы',
      en: 'Uploaded documents',
    },
    upload_pdf: {
      ru: 'Загрузить PDF',
      en: 'Upload PDF',
    },
    no_docs: {
      ru: 'Пока нет документов',
      en: 'No documents yet',
    },
    signed_docs: {
      ru: 'Подписанные',
      en: 'Signed',
    },
    uploaded: {
      ru: 'Загруженные',
      en: 'Uploaded',
    },
    title: {
      ru: 'Название',
      en: 'Name',
    },
    type: {
      ru: 'Тип',
      en: 'Type',
    },
    manager: {
      ru: 'Менеджер',
      en: 'Manager',
    },
    recipient: {
      ru: 'Получатель',
      en: 'Recipient',
    },
    status: {
      ru: 'Статус',
      en: 'Status',
    },
    actions: {
      ru: 'Действия',
      en: 'Actions',
    },
    draft: {
      ru: 'Черновик',
      en: 'Draft',
    },
    sent: {
      ru: 'Отправлен',
      en: 'Sent',
    },
    signed: {
      ru: 'Подписан',
      en: 'Signed',
    },
    revoked: {
      ru: 'Отозван',
      en: 'Withdrawn',
    },
    send: {
      ru: 'Отправить',
      en: 'Send',
    },
    print: {
      ru: 'Напечатать',
      en: 'Print',
    },
    download: {
      ru: 'Скачать',
      en: 'Download',
    },
    revoke: {
      ru: 'Отозвать',
      en: 'Withdraw',
    },
    delete: {
      ru: 'Удалить',
      en: 'Delete',
    },
    confirm_revoke: {
      ru: 'Отозвать документ?',
      en: 'Confirm withdrawal?',
    },
    confirm_delete: {
      ru: 'Удалить документ?',
      en: 'Confirm delete?',
    },
  },

  Doc: {
    errorUpdateTitle: {
      ru: 'Не удалось обновить заголовок документа',
      en: 'Failed to update document title',
    },

    back: {
      ru: 'Назад',
      en: 'Back',
    },
    loading_pdf: {
      ru: 'Загрузка PDF...',
      en: 'Upload PDF...',
    },
    error_loading_pdf: {
      ru: 'Ошибка загрузки PDF',
      en: 'Uploading PDF error',
    },
    no_pdf_found: {
      ru: 'Ничего не найдено',
      en: 'Not found',
    },
    document_title: {
      ru: 'Название документа',
      en: 'Document name',
    },
    document_revoked: {
      ru: 'Документ отозван',
      en: 'Document withdrawn',
    },
    revoke: {
      ru: 'Отозвать',
      en: 'Withdraw',
    },
    delete: {
      ru: 'Удалить',
      en: 'Calcel',
    },
    sending_document: {
      ru: 'Отправка документа',
      en: 'Sending of document',
    },
    recipient: {
      ru: 'Получатель:',
      en: 'Recipient:',
    },
    send_document: {
      ru: 'Отправить документ',
      en: 'Send document',
    },
    print: {
      ru: 'Печать',
      en: 'Print',
    },
    download: {
      ru: 'Скачать',
      en: 'Скачать',
    },
    select_recipient: {
      ru: 'Выберите получателя',
      en: 'Choose recipient',
    },
    client_list: {
      ru: 'Список клиентов',
      en: 'List of clients',
    },
    notify_whatsapp: {
      ru: 'Уведомить по WhatsApp',
      en: 'Notify by WhatsApp',
    },
    preview: {
      ru: 'Предпросмотр',
      en: 'Preview',
    },
    signature_place: {
      ru: 'Место подписи',
      en: 'Signature',
    },
    stamp_place: {
      ru: 'Место печати',
      en: 'Stamp',
    },
    save: {
      ru: 'Сохранить',
      en: 'Save',
    },
    cancel: {
      ru: 'Отмена',
      en: 'Calcel',
    },
    enter_document_title: {
      ru: 'Введите название документа',
      en: 'Enter document name',
    },
  },

  showStatus: {
    individual: {
      ru: 'физ. лицо',
      en: 'individual',
    },
    soleProprietor: {
      ru: 'ИП',
      en: 'IE',
      // en: 'Individual entrepreneur',
    },
    legalEntity: {
      ru: 'юр. лицо',
      en: 'Entity',
    },
    passport: {
      ru: 'Паспорт',
      en: 'Passport',
    },
    phone: {
      ru: 'Телефон',
      en: 'Phone',
    },
    email: {
      ru: 'Email',
      en: 'Email',
    },
    position: {
      ru: 'Должность',
      en: 'Position',
    },
    companyName: {
      ru: 'Название компании',
      en: 'Company name',
    },
    inn: {
      ru: 'ИНН',
      en: 'Taxpayer Identification Number',
    },
    kpp: {
      ru: 'КПП',
      en: 'Tax Registration Reason Code',
    },
    ogrn: {
      ru: 'ОГРН',
      en: 'Registration Number',
    },
    director: {
      ru: 'Ген. директор',
      en: 'CEO',
    },
    legalAddress: {
      ru: 'Юр. адрес',
      en: 'Legal Address',
    },
    bankDetails: {
      ru: 'Реквизиты',
      en: 'Bank details',
    },
    contractAccount: {
      ru: 'Счёт для договора',
      en: 'Account for agreement',
    },
  },

  DB: {
    enterCompany: {
      ru: 'Пожалуйста, введите название компании',
      en: 'Please enter company name',
    },
    enterAddress: {
      ru: 'Пожалуйста, введите юридический адрес',
      en: 'Please enter legal address',
    },
    title: {
      ru: 'База данных',
      en: 'Database',
    },
    backButton: {
      ru: 'Назад',
      en: 'Back',
    },
    description: {
      ru: 'Здесь хранятся внесённые ранее данные организаций и физ. лиц',
      en: 'Here are stored previously entered data of organizations and individuals',
    },
    columns: {
      organizationName: {
        ru: 'Организация / ФИО',
        en: 'Organization / Full Name',
      },
      creationDate: {
        ru: 'Дата создания',
        en: 'Creation Date',
      },
      actions: {
        ru: 'Действия',
        en: 'Actions',
      },
    },
    edit: {
      ru: 'Редактировать',
      en: 'Edit',
    },
    delete: {
      ru: 'Удалить',
      en: 'Delete',
    },
    confirmDelete: {
      ru: 'Удалить запись?',
      en: 'Delete record?',
    },
    noData: {
      title: {
        ru: 'Здесь пока ничего нет',
        en: 'Nothing here yet',
      },
      description: {
        ru: 'Но появятся карточки клиентов и организаций',
        en: 'But client and organization cards will appear',
      },
      addButton: {
        ru: 'Добавить',
        en: 'Add',
      },
    },
    modal: {
      title: {
        create: {
          ru: 'Создать',
          en: 'Create',
        },
        edit: {
          ru: 'Редактировать',
          en: 'Edit',
        },
      },
      fields: {
        name: {
          ru: 'ФИО',
          en: 'Full Name',
        },
        passport: {
          ru: 'Паспорт',
          en: 'Passport',
        },
        phone: {
          ru: 'Телефон',
          en: 'Phone',
        },
        email: {
          ru: 'Email',
          en: 'Email',
        },
        position: {
          ru: 'Должность',
          en: 'Position',
        },
        companyName: {
          ru: 'Название компании',
          en: 'Company Name',
        },
        inn: {
          ru: 'ИНН',
          en: 'Taxpayer Identification Number',
        },
        kpp: {
          ru: 'КПП',
          en: 'Tax Registration Reason Code',
        },
        ogrn: {
          ru: 'ОГРН',
          en: 'Registration Number',
        },
        director: {
          ru: 'Ген. директор',
          en: 'CEO',
        },
        address: {
          ru: 'Юридический адрес',
          en: 'Legal Address',
        },
      },
      saveButton: {
        ru: 'Сохранить',
        en: 'Save',
      },
      closeButton: {
        ru: 'Закрыть',
        en: 'Close',
      },
      organization: {
        ru: 'Организация',
        en: 'Organization',
      },
    },
  },
  ModalVerification: {
    title: {
      ru: 'Верификация клиента',
      en: 'Client verification',
    },
    steps: {
      step1: {
        ru: 'Проверка',
        en: 'Check',
      },
      step2: {
        ru: 'Данные организации',
        en: 'Details of organization',
      },
      step3: {
        ru: 'Менеджер',
        en: 'Manager',
      },
    },
    formFields: {
      name: {
        ru: 'ФИО',
        en: 'Name',
      },
      passport: {
        ru: 'Паспорт',
        en: 'Passport',
      },
      phone: {
        ru: 'Телефон',
        en: 'Phone',
      },
      email: {
        ru: 'Email',
        en: 'Email',
      },
      position: {
        ru: 'Должность',
        en: 'Position',
      },
    },
    requestStamp: {
      ru: 'Запросить печать',
      en: 'Request stamp',
    },
    stampRequested: {
      ru: 'Печать запрошена',
      en: 'Stamp requested',
    },
    buttons: {
      cancel: {
        ru: 'Отмена',
        en: 'Calcel',
      },
      next: {
        ru: 'Далее',
        en: 'Next',
      },
      back: {
        ru: 'Назад',
        en: 'Back',
      },
      verify: {
        ru: 'Верифицировать',
        en: 'Verify',
      },
    },
    managerSelection: {
      title: {
        ru: 'Выберите менеджера клиента',
        en: 'Choose client manager',
      },
      listPlaceholder: {
        ru: 'Список менеджеров',
        en: 'List of managers',
      },
      managerAssigned: {
        ru: (manager_name: string) => `Менеджер ${manager_name} назначен`,
        en: (manager_name: string) => `Manager ${manager_name} assigned`,
      },
    },
    errors: {
      requestStampError: {
        ru: 'Ошибка',
        en: 'Error',
      },
    },
  },
  ModalStatus: {
    title: {
      ru: 'Данные организации',
      en: 'Details of organization',
    },
    organizationList: {
      ru: 'Список организаций',
      en: 'List of organizations',
    },
    assignAccount: {
      ru: 'Реквизиты',
      en: 'Bank details',
    },
    contractAccount: {
      ru: 'Счёт для договора',
      en: 'Accountfor agreement',
    },
    requestStamp: {
      ru: 'Запросить печать',
      en: 'Request stamp',
    },
    stampRequested: {
      ru: 'Печать запрошена',
      en: 'Stamp requested',
    },
    physicalPerson: {
      ru: 'физ. лицо',
      en: 'Individual',
    },
    individualEntrepreneur: {
      ru: 'ИП',
      en: 'Individual Entrepreneu',
    },
    legalEntity: {
      ru: 'юр. лицо',
      en: 'Entity',
    },
    passport: {
      ru: 'Паспорт',
      en: 'Passport',
    },
    phone: {
      ru: 'Телефон',
      en: 'Telephone',
    },
    email: {
      ru: 'Email',
      en: 'Email',
    },
    position: {
      ru: 'Должность',
      en: 'Position',
    },
    companyName: {
      ru: 'Название компании',
      en: 'Company name',
    },
    inn: {
      ru: 'ИНН',
      en: 'Taxpayer Identification Number',
    },
    kpp: {
      ru: 'КПП',
      en: 'Tax Registration Reason Code',
    },
    ogrn: {
      ru: 'ОГРН',
      en: 'Registration Number',
    },
    director: {
      ru: 'Ген. директор',
      en: 'CEO',
    },
    address: {
      ru: 'Юр. адрес',
      en: 'Legal address',
    },
    deleteAccount: {
      ru: 'Удалить счёт?',
      en: 'Delete account?',
    },
    delete: {
      ru: 'Удалить',
      en: 'Delete',
    },
    cancel: {
      ru: 'Отмена',
      en: 'Cancel',
    },
    next: {
      ru: 'Далее',
      en: 'Next',
    },
    back: {
      ru: 'Назад',
      en: 'Back',
    },
    save: {
      ru: 'Сохранить',
      en: 'Save',
    },
  },
  ModalManager: {
    title: {
      ru: 'Выберите менеджера клиента',
      en: 'Choose client manager',
    },
    managerList: {
      ru: 'Список менеджеров',
      en: 'List of managers',
    },
    save: {
      ru: 'Сохранить',
      en: 'Save',
    },
    selectManagerError: {
      ru: 'Выберите менеджера',
      en: 'Choose manager',
    },
    clientDataError: {
      ru: 'Нет данных по клиенту',
      en: 'No client data',
    },
    managerAssigned: {
      ru: (manager_name: string) => `Менеджер ${manager_name} назначен`,
      en: (manager_name: string) => `Manager ${manager_name} assigned`,
    },
  },
  ModalEditClient: {
    title: {
      ru: 'Данные клиента',
      en: 'Client data',
    },
    name: {
      ru: 'ФИО',
      en: 'Name',
    },
    passport: {
      ru: 'Паспорт',
      en: 'Passport',
    },
    phone: {
      ru: 'Телефон',
      en: 'Phone',
    },
    email: {
      ru: 'Email',
      en: 'Email',
    },
    position: {
      ru: 'Должность',
      en: 'Position',
    },
    save: {
      ru: 'Сохранить',
      en: 'Save',
    },
  },
  Clients: {
    orgAdded: {
      ru: 'Организация добавлена',
      en: 'Organization added',
    },
    accAdded: {
      ru: 'Счет добавлен',
      en: 'Account added',
    },
    stampError: {
      ru: 'Печать пользователя не загружена. Свяжитесь с клиентом.',
      en: 'User stamp not uploaded. Contact client.',
    },
    docsError: {
      ru: 'Клиент еще не предоставил документы',
      en: 'Client has not provided documents yet',
    },
    clientDeleted: {
      ru: 'Клиент успешно удалён',
      en: 'Client has been successfully deleted',
    },
    errorDeleteClient: {
      ru: 'Ошибка при удалении клиента',
      en: 'Error in deleting client',
    },
    enterCompany: {
      ru: 'Пожалуйста, введите название компании',
      en: 'Please enter company name',
    },
    enterBank: {
      ru: 'Пожалуйста, введите название банка',
      en: 'Please enter bank name',
    },
    enterAddress: {
      ru: 'Пожалуйста, введите юридический адрес',
      en: 'Please enter legal address',
    },

    title: {
      ru: 'Клиенты',
      en: 'Clients',
    },
    database: {
      ru: 'База данных',
      en: 'Database',
    },
    active: {
      ru: 'Активные',
      en: 'Active',
    },
    needVerification: {
      ru: 'Нужна верификация',
      en: 'Need verification',
    },
    archive: {
      ru: 'Архив',
      en: 'Archive',
    },
    assignOrganization: {
      ru: 'Присвойте организацию клиенту',
      en: 'Assign organization to client',
    },
    assign: {
      ru: 'Присвоить',
      en: 'Assign',
    },
    assignManager: {
      ru: 'Назначьте менеджера клиенту',
      en: 'Assign manager to client',
    },
    assignManagerButton: {
      ru: 'Назначить',
      en: 'Assign',
    },
    organizationData: {
      ru: 'Данные организации',
      en: 'Organization data',
    },
    organizationNotAssigned: {
      ru: 'К клиенту ещё не прикреплена организация',
      en: 'No organization is assigned to client',
    },
    requisites: {
      ru: 'Реквизиты',
      en: 'Bank details',
    },
    contractAccount: {
      ru: 'Счёт для договора',
      en: 'Account for agreement',
    },
    documents: {
      ru: 'Документы',
      en: 'Documents',
    },
    documentsForSigning: {
      ru: 'Документы на подписание',
      en: 'Documents for signature',
    },
    requestStamp: {
      ru: 'Запросить печать',
      en: 'Request stamp',
    },
    stampRequested: {
      ru: 'Печать запрошена',
      en: 'Stamp requested',
    },
    revokeVerification: {
      ru: 'Отозвать верификацию',
      en: 'Withdraw verification',
    },
    revokeVerificationConfirm: {
      ru: 'После этого клиент не сможет подписывать документы.\nВерификацию можно будет провести повторно.',
      en: 'After this, client will not be able to sign documents.\nVerification can be repeated.',
    },
    sendDocument: {
      ru: 'Отправить документ',
      en: 'Send document',
    },
    sendFromUploaded: {
      ru: 'Из загруженных',
      en: 'Send from uploaded',
    },
    uploadPDF: {
      ru: 'Загрузить PDF',
      en: 'Upload PDF',
    },
    deleteClient: {
      ru: 'Удалить клиента?',
      en: 'Delete client?',
    },
    deleteClientConfirm: {
      ru: 'Это действие необратимо!\nКлиент и все связанные с ним данные будут удалены.',
      en: 'This action is irreversible!\nClient and all associated data will be deleted.',
    },
    delete: {
      ru: 'Удалить',
      en: 'Delete',
    },
    cancel: {
      ru: 'Отмена',
      en: 'Cancel',
    },
    addOrganization: {
      ru: 'Добавить организацию',
      en: 'Add organization',
    },
    addRequisites: {
      ru: 'Добавить реквизиты',
      en: 'Add bank details',
    },
    companyName: {
      ru: 'Название компании',
      en: 'Company name',
    },
    inn: {
      ru: 'ИНН',
      en: 'Taxpayer Identification Number',
    },
    kpp: {
      ru: 'КПП',
      en: 'Tax Registration Reason Code',
    },
    ogrn: {
      ru: 'ОГРН',
      en: 'Registration Number',
    },
    director: {
      ru: 'Ген. директор',
      en: 'CEO',
    },
    address: {
      ru: 'Юридический адрес',
      en: 'Legal Address',
    },
    save: {
      ru: 'Сохранить',
      en: 'Save',
    },
    bankName: {
      ru: 'Наименование банка',
      en: 'Bank name',
    },
    bik: {
      ru: 'БИК',
      en: 'BIC',
    },
    currentAccount: {
      ru: 'Расчетный счет',
      en: 'Account',
    },
    corrAccount: {
      ru: 'Корреспондентский счет',
      en: 'Correspondent account',
    },
  },
  ForgotPass: {
    titleStep1: {
      ru: 'Восстановление пароля',
      en: 'Reset password',
    },
    titleStep2: {
      ru: 'Отправили ссылку',
      en: 'Send link',
    },
    descriptionStep1: {
      ru: 'Введите email. На него отправим ссылку для восстановления пароля',
      en: 'Enter your email. We will send you link to reset your password',
    },
    descriptionStep2: {
      ru: (email: string) =>
        `На ${email} отправили ссылку для восстановления пароля. Если письмо не пришло, проверьте папку «Спам»`,
      en: (email: string) =>
        `A link to reset your password has been sent to ${email}. If you haven't received this email, check your Spam box`,
    },
    placeholderEmail: {
      ru: 'Email',
      en: 'Email',
    },
    buttonRecover: {
      ru: 'Восстановить',
      en: 'Reset',
    },
    buttonResend: {
      ru: 'Отправить ещё раз',
      en: 'Resend',
    },
    buttonResendWithTimer: {
      ru: (seconds: number) => `Отправить ещё раз (${seconds.toString().padStart(2, '0')} сек)`,
      en: (seconds: number) => `Resend (${seconds.toString().padStart(2, '0')} sec)`,
    },
  },
  NotifyPanel: {
    title: {
      ru: 'Уведомления',
      en: 'Notifications',
    },
    noNotifications: {
      ru: 'Нет уведомлений',
      en: 'No notifications',
    },
    selectOrder: {
      ru: '--- Выберите ---',
      en: '--- Choose ---',
    },
    orderByUnread: {
      ru: 'Сначала непрочитанные',
      en: 'Order by unread',
    },
    orderByNewest: {
      ru: 'Сначала новые',
      en: 'Order by newest',
    },
    close: {
      ru: 'Закрыть',
      en: 'Close',
    },
  },
  Notifications: {
    typeMsg: {
      ru: 'Тип сообщения',
      en: 'Message Type',
    },
    msg: {
      ru: 'Сообщение',
      en: 'Message',
    },

    mainTitle: {
      ru: 'Настройки уведомлений и рассылок',
      en: 'Notification and mailing settings',
    },
    nothingFound: {
      ru: 'Ничего не найдено',
      en: 'Not found',
    },
    pushMessagesTitle: {
      ru: 'Push-сообщения',
      en: 'Push-message',
    },
    create: {
      ru: 'Создать',
      en: 'Create',
    },
    newPushMessageTitle: {
      ru: 'Новое пуш-сообщение',
      en: 'New push-message',
    },
    editMessageTitle: {
      ru: 'Редактирование сообщения',
      en: 'Edit message',
    },
    save: {
      ru: 'Сохранить',
      en: 'Save',
    },
    delete: {
      ru: 'Удалить',
      en: 'Delete',
    },
    pause: {
      ru: 'Приостановить',
      en: 'Suspend',
    },
    start: {
      ru: 'Запустить',
      en: 'Start',
    },
    paused: {
      ru: 'Приостановлено',
      en: 'Suspended',
    },
    expired: {
      ru: 'Истёк срок',
      en: 'Expired',
    },
    now: {
      ru: 'Сейчас',
      en: 'Now',
    },
    type: {
      ru: 'Тип',
      en: 'Type',
    },
    dates: {
      ru: 'Даты',
      en: 'Dates',
    },
    sending: {
      ru: 'Отправка',
      en: 'Send',
    },
    time: {
      ru: 'Время',
      en: 'Time',
    },
    whatsappNotificationsTitle: {
      ru: 'Настройки WhatsApp-уведомлений',
      en: 'WhatsApp Notification Settings',
    },
    messageText: {
      ru: 'Текст сообщения',
      en: 'Message type',
    },
    whatsappMessageTitle: {
      ru: 'Текст сообщения WhatsApp',
      en: 'WhatsApp Message Text',
    },
    fillInAllFields: {
      ru: 'Заполните все поля',
      en: 'Fill all fields',
    },
    textTooLong: {
      ru: 'Слишком длинный текст',
      en: 'Too long text',
    },
    apply: {
      ru: 'Применить',
      en: 'Apply',
    },
    createMessage: {
      ru: 'Cоздать сообщение',
      en: 'Create message',
    },
    clients: {
      ru: 'Клиенты',
      en: 'Clients',
    },
    searchClients: {
      ru: 'Поиск клиентов',
      en: 'Search clients',
    },
    heading: {
      ru: 'ЗАГОЛОВОК',
      en: 'HEADING',
    },
    text: {
      ru: 'ТЕКСТ',
      en: 'TEXT',
    },
    duplicateToWhatsapp: {
      ru: 'Дублировать в WhatsApp',
      en: 'Duplicate in WhatsApp',
    },
    dateStart: {
      ru: 'ДАТА СТАРТА',
      en: 'START DATE',
    },
    dateEnd: {
      ru: 'ДАТА ОКОНЧАНИЯ',
      en: 'END DATE',
    },
    time1: {
      ru: 'ВРЕМЯ',
      en: 'TIME',
    },
    frequency: {
      ru: 'ЧАСТОТА',
      en: 'FREQUENCY',
    },
    chooseFrequency: {
      ru: 'Выберите частоту',
      en: 'Choose frequency',
    },
    add: {
      ru: 'Добавить',
      en: 'Add',
    },
  },

  frequencies: {
    hourly: {
      ru: 'Ежечасно',
      en: 'Hourly',
    },
    daily: {
      ru: 'Ежедневно',
      en: 'Daily',
    },
    weekly: {
      ru: 'Еженедельно',
      en: 'Weekly',
    },
    monthly: {
      ru: 'Ежемесячно',
      en: 'Monthly',
    },
  },
  options: {
    automated: {
      ru: 'Автоматизированное',
      en: 'Automated',
    },
    custom: {
      ru: 'Произвольное',
      en: 'Arbitrary',
    },
  },

  Login: {
    title: {
      ru: 'Авторизация',
      en: 'Authorization',
    },
    subtitle: {
      ru: 'Введите email и пароль',
      en: 'Enter email and password',
    },
    remember: {
      ru: 'Запомнить',
      en: 'Remember',
    },
    forgotPassword: {
      ru: 'Забыли пароль?',
      en: 'Forgot password?',
    },
    placeholderEmail: {
      ru: 'Email',
      en: 'Email',
    },
    placeholderPassword: {
      ru: 'Пароль',
      en: 'Password',
    },
    buttonLogin: {
      ru: 'Войти',
      en: 'Login',
    },
  },
  NewPass: {
    pageTitleRecovery: {
      ru: 'Создайте новый пароль',
      en: 'Create new password',
    },
    pageTitleRegistration: {
      ru: 'Создайте пароль\nдля входа в систему',
      en: 'Create a password \n to log in',
    },
    passwordsDoNotMatch: {
      ru: 'Пароли не совпадают',
      en: 'Passwords do not match',
    },
    passwordSavedSuccess: {
      ru: 'Пароль успешно сохранен! Вы можете войти в систему используя свою почту и пароль.',
      en: 'Password saved successfully! You can log in using your email and password.',
    },
    placeholderNewPassword: {
      ru: 'Новый пароль',
      en: 'New password',
    },
    placeholderRepeatPassword: {
      ru: 'Повторите пароль',
      en: 'Repeat password',
    },
    buttonSave: {
      ru: 'Сохранить',
      en: 'Save',
    },
  },
  DocTypes: {
    contract: {
      ru: 'Договор',
      en: 'Agreement',
    },
    additional_agreement: {
      ru: 'Дополнительное соглашение к договору',
      en: 'Amendment to agreement',
    },
    annex_to_contract: {
      ru: 'Приложение к договору',
      en: 'Annex to agreement',
    },
    power_of_attorney: {
      ru: 'Доверенность',
      en: 'Power of Attorney',
    },
    procedural_document: {
      ru: 'Процессуальный документ',
      en: 'Procedural document',
    },
    claim: {
      ru: 'Претензия',
      en: 'Warning',
    },
    letter: {
      ru: 'Письмо',
      en: 'Letter',
    },
  },

  Rules: {
    PASSWORD: {
      ru: 'Пожалуйста, введите пароль',
      en: 'Please enter password',
    },
    PASSPORT: [
      {
        ru: 'Пожалуйста, введите паспортные данные',
        en: 'Please enter passport',
      },
      {
        ru: 'Максимальное количество знаков - 20',
        en: 'Maximum number of characters is 20',
      },
      {
        ru: 'Поле должно содержать только цифры и буквы',
        en: 'This field must contain only numbers and letters',
      },
    ],
    PHONE: [
      {
        ru: 'Пожалуйста, введите номер телефон',
        en: 'Please enter phone number',
      },
      {
        ru: 'Максимальное количество знаков - 15',
        en: 'Maximum number of characters is 15',
      },
    ],
    EMAIL: [
      {
        ru: 'Пожалуйста, введите email',
        en: 'Please enter email',
      },
      {
        ru: 'Максимальное количество знаков - 50',
        en: 'Maximum number of characters is 50',
      },
    ],
    NAME: [
      {
        ru: 'Пожалуйста, введите ФИО',
        en: 'Please enter name',
      },
      {
        ru: 'ФИО должна содержать от 1 до 32 символов',
        en: 'Full name must be between 1 and 32 characters',
      },
      {
        ru: 'ФИО должна содержать только русские или латинские буквы, символ "-" и пробел',
        en: 'Full name must contain only Russian or Latin letters, "-" symbol and a space',
      },
    ],
    INN: [
      {
        ru: 'Пожалуйста, введите ИНН',
        en: 'Please enter Taxpayer Identification Number',
      },
      {
        ru: 'ИНН - от 9 до 14 цифр, латинских букв',
        en: 'Taxpayer Identification Number shall consist of 9-14 symbols',
      },
      {
        ru: 'ИНН должен содержать только цифры',
        en: 'Taxpayer Identification Number shall consist of digits only',
      },
    ],
    KPP: [
      {
        ru: 'Пожалуйста, введите КПП',
        en: 'Please enter Tax Registration Reason Code',
      },
      {
        ru: 'КПП должен состоять из 9 цифр',
        en: 'Tax Registration Reason Code shall consist of 9 digits',
      },
      {
        ru: 'КПП должен содержать только цифры',
        en: 'Tax Registration Reason Code shall consist of digits only',
      },
    ],
    OGRN: [
      {
        ru: 'Пожалуйста, введите ОГРН',
        en: 'Please enter Registration Number',
      },
      {
        ru: 'ОГРН должен состоять из 13 цифр',
        en: 'Registration Number shall consist of 13 digits ',
      },
      {
        ru: 'ОГРН должен содержать только цифры',
        en: 'Registration Number shall consist of digits only',
      },
    ],
    DIRECTOR: [
      {
        ru: 'Пожалуйста, введите ФИО директора',
        en: 'Please enter CEO name',
      },
      {
        ru: 'ФИО должна содержать от 1 до 32 символов',
        en: 'Full name must be between 1 and 32 characters',
      },
      {
        ru: 'ФИО должна содержать только русские или латинские буквы, символ "-" и пробел',
        en: 'Full name must contain only Russian or Latin letters, "-" symbol and a space',
      },
    ],
    POSITION: {
      ru: 'Максимальное количество знаков - 32',
      en: 'Maximum number of characters is 32',
    },
    BIK: [
      {
        ru: 'Пожалуйста, введите БИК',
        en: 'Please enter BIC',
      },
      {
        ru: 'БИК должен состоять из 9 цифр',
        en: 'BIC shall consist of 9 digits',
      },
      {
        ru: 'БИК должен содержать только цифры',
        en: 'BIC shall consist of digits only',
      },
    ],
    ACCOUNT: [
      {
        ru: 'Пожалуйста, введите расчетный счет',
        en: 'Please enter bank account',
      },
      {
        ru: 'Расчетный счет должен состоять из 20 цифр',
        en: 'Bank account shall consist of 20 digits',
      },
      {
        ru: 'Расчетный счет должен содержать только цифры',
        en: 'Bank account shall consist of digits only',
      },
    ],
    COR_ACCOUNT: [
      {
        ru: 'Пожалуйста, введите корреспондентский счет',
        en: 'Please enter correspondent bank account',
      },
      {
        ru: 'Корреспондентский счет должен состоять из 20 цифр',
        en: 'Correspondent bank account shall consist of 20 digits',
      },
      {
        ru: 'Корреспондентский счет должен содержать только цифры',
        en: 'Correspondent bank account shall consist of digits only',
      },
    ],
    NUMBER: {
      ru: 'Может содержать от 1 до 3 цифр',
      en: 'May be between 1 до 3 digits',
    },
  },
};
