import React from 'react';
import { EditOutlined, FileTextOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { langs } from '../../utils/lang';
import globalController from '../../mobx/GlobalController';

interface SettingsItemProps {
  name: string;
  onLoad: () => void;
  onView: () => void;
  canChange?: boolean;
  hasContent: boolean;
}

const languages = langs.SettingsItem;

const SettingsItem = observer((props: SettingsItemProps) => {
  const { name, onLoad, onView, canChange = true, hasContent } = props;
  const lang = globalController.getLang();

  return (
    <div className="w-full flex items-center justify-between">
      <div className="flex items-center gap-2">
        <FileTextOutlined />
        {name}
      </div>
      <div className="flex items-center gap-4">
        {canChange && !hasContent && (
          <button
            onClick={!!onLoad && onLoad}
            className="rounded flex items-center relative px-2 py-1 cursor-pointer bg-white gap-2"
            style={{ border: '1px solid rgba(0,0,0,.15)' }}
          >
            <UploadOutlined />
            {languages.upload[lang]}
          </button>
        )}
        {hasContent && (
          <div className="flex gap-4">
            <button
              onClick={!!onLoad && onLoad}
              className="rounded flex items-center relative px-2 py-1 cursor-pointer bg-white gap-2"
              style={{ border: '1px solid rgba(0,0,0,.15)' }}
            >
              <EditOutlined />
              {languages.edit[lang]}
            </button>
            <button
              onClick={onView}
              className="rounded flex items-center relative px-2 py-1 cursor-pointer bg-white gap-2"
              style={{ border: '1px solid rgba(0,0,0,.15)' }}
            >
              <SearchOutlined />
              {languages.view[lang]}
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

export default SettingsItem;
