import {
  ArrowRightOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileTextOutlined,
  PlusOutlined,
  PrinterOutlined,
  SaveOutlined,
  StopOutlined,
} from '@ant-design/icons';

import { Button, Dropdown, Form, Input, Modal, Popconfirm, Radio, Tabs, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Block, BlueBox, Cell, List, Row } from '../../components/PageElements';
import Spinner from '../../components/Spinner';
import Table from '../../components/Table';
import globalController from '../../mobx/GlobalController';
import {
  BASE_URL,
  COMPANY_TYPES,
  COMPANY_TYPES_NAMES_FN,
  DOC_TYPES_FN,
  RQ_OPT,
  RULES_FN,
} from '../../utils/consts';
import { isUserAdmin, isUserSupervisor } from '../../utils/helpers';
import {
  clientEdit,
  clientRevokeVerification,
  clientVerification,
  createBankAccount,
  createCompany,
  deleteClient,
  deleteDoc,
  editBankAccount,
  getBankAccounts,
  getClient,
  getClients,
  getCompanies,
  getDoc,
  getDocs,
  getManagers,
  requestClientStamp,
  setStateDoc,
} from '../../utils/httpServices/global';
import ModalEditClient from './ModalEditClient';
import ModalManager from './ModalManager';
import ModalStatus from './ModalStatus';
import ModalVerification from './ModalVerification';
import { ImagesNames } from './imagesNames';
import { UPLOADS_PATH, DB_PATH } from '../../utils/routes_consts';
import { openLinkForPrint } from '../../utils/openForPrint';
import { langs } from '../../utils/lang';

const { TextArea } = Input;

const initModals = {
  manager: false,
  status: false,
  edit: false,
  verification: false,
};

const languages = langs.Clients;

const Clients = observer(() => {
  const navigate = useNavigate();
  const user = globalController.getUser();
  const lang = globalController.getLang();
  const DOC_TYPES = DOC_TYPES_FN(lang);
  const RULES = RULES_FN(lang);
  const COMPANY_TYPES_NAMES = COMPANY_TYPES_NAMES_FN(lang);
  const [formOrg] = Form.useForm();
  const [formAcc] = Form.useForm();
  const [searchStr, setSearchStr] = useState('');
  const [searchText, setSearchText] = useState('');
  const [activeIdx, setActiveIdx] = useState(0);
  const [activeId, setActiveId] = useState(0);
  const [activeTab, setActiveTab] = useState('active');
  const [clientData, setClientData] = useState<any>(null);
  const [showModal, setShowModal] = useState(initModals);
  const [currentPage, setCurrentPage] = useState(1);
  const [showImage, setShowImage] = useState('');
  const [isShowCreateOrgModal, setIsShowCreateOrgModal] = useState<boolean | any>(false);
  const [isShowCreateAccModal, setIsShowCreateAccModal] = useState<boolean | any>(false);
  const [company, setCompany] = useState<null | any>(null);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [sortByName, setSortByName] = useState('');
  const [step, setStep] = useState(0);

  const queryClient = useQueryClient();

  const {
    isLoading,
    isFetching,
    data,
    refetch: refetchClients,
  } = useQuery(
    ['clients', activeTab, currentPage, searchText, sortByName],
    () => getClients(activeTab, currentPage, searchText, sortByName),
    RQ_OPT,
  );

  const {
    isLoading: isLoadingClient,
    isFetching: isFetchingClient,
    data: dataClient,
    refetch: refetchClient,
  } = useQuery(
    ['client', clientData?.id || 0, data ? JSON.stringify(data).length : ''],
    () => getClient(clientData?.id || 0),
    RQ_OPT,
  );

  const {
    data: dataClientDocs,
    isLoading: isLoadingCD,
    refetch,
  } = useQuery(
    ['clientDocs', clientData?.id || 0],
    () => getDocs({ customerId: clientData?.id || 0, perPage: 999 }),
    RQ_OPT,
  );

  const {
    isLoading: isLoadingManagers,
    isFetching: isFetchingManagers,
    data: dataManagers,
  } = useQuery(['managers'], () => getManagers(), { ...RQ_OPT, enabled: isUserAdmin(user?.roles) });

  const {
    data: dataCompanies,
    isLoading: isLoadingCompanies,
    isFetching: isFetchingCompanies,
    refetch: refetchCompanies,
  } = useQuery(['companiez'], () => getCompanies(), RQ_OPT);

  const {
    data: dataAccounts,
    isLoading: isLoadingAccounts,
    isFetching: isFetchingAccounts,
    refetch: refetchAccounts,
  } = useQuery(
    ['accounts', company?.id || 0],
    () => (company?.id ? getBankAccounts(company.id) : []),
    RQ_OPT,
  );

  // Выбор юзера в списке после его верификации
  useEffect(() => {
    if (activeId && data?.items) {
      const index = data.items.findIndex((item: any) => item.id === activeId);
      if (index > -1) {
        setActiveIdx(index);
        setActiveId(0);
      }
    }
  }, [data, activeId]);

  const items = data?.items || [];

  const loading =
    isLoading ||
    isFetching ||
    isLoadingClient ||
    isFetchingClient ||
    isLoadingManagers ||
    isFetchingManagers ||
    isLoadingCompanies ||
    isFetchingCompanies ||
    isLoadingAccounts ||
    isFetchingAccounts;

  const onFinishAddCompany = (values: any) => {
    if (!values) {
      toast(langs.ModalVerification.errors.requestStampError[lang]);
      return;
    }

    const dataAdd = {
      ...values,
      type: COMPANY_TYPES[selectedStatus],
      customerId: clientData?.id || 0,
    };
    createCompany(dataAdd)
      .then((res: any) => {
        if (res?.id) {
          toast(languages.orgAdded[lang], { type: 'success' });
          formOrg.resetFields();
          refetchCompanies();
          setIsShowCreateOrgModal(false);
        }
        return res;
      })
      .catch((error) => {
        console.log(error);
        toast(error?.message || typeof error !== 'string' ? JSON.stringify(error) : error, {
          type: 'error',
        });
      });
  };

  const onFinishAddAcc = (values: any) => {
    const dataAcc = { ...values, isDefaultAccount: 1, companyId: isShowCreateAccModal?.id };
    createBankAccount(dataAcc).then((res: any) => {
      if (res?.id) {
        toast(languages.accAdded[lang], { type: 'success' });
        refetchAccounts();
        setIsShowCreateAccModal(false);
      }
    });
  };

  const handleAccount = (index: number) => {
    const item = dataAccounts?.items?.[index];
    if (item) {
      const values = { ...item, isDefaultAccount: 1, companyId: company?.id || 0 };
      editBankAccount(values).then((res: any) => {
        refetchAccounts();
        return res;
      });
    }
  };

  const onEdit = (id: number | string, data: any) => clientEdit(id, data);

  const openForPrint = (id: number) => {
    getDoc(id).then((response) => {
      const link = response?.signedLink || response?.link;
      if (link) openLinkForPrint(link);
    });
  };

  const deleteDocumentById = (id: number) => {
    deleteDoc(id).then((response) => {
      response?.status !== 'error' && refetch();
    });
  };

  const sentDocToSign = (id: number) => {
    setStateDoc({ id, state: 'sent' }).then((response) => {
      response.status !== 'error' && refetch();
    });
  };

  const revokeDoc = (id: number) => {
    setStateDoc({ id, state: 'revoked' }).then((response) => {
      response.status !== 'error' && refetch();
    });
  };

  const onVerify = (id: number | string) =>
    clientVerification(id).then((response) => {
      if (response.id) {
        queryClient.invalidateQueries('clients');
        setActiveTab('active');
        setShowModal(initModals);
        setActiveId(response.id);
        return;
      }
      if (response.error?.response?.data?.message === 'Не загружена печать') {
        if (clientData.needStamp) {
          setStep(0);
        } else {
          toast(languages.stampError[lang], { type: 'error' });
        }
      }
    });

  const columns = useMemo(
    () => [
      { title: langs.Docs.title[lang], dataIndex: 'name' },
      {
        title: langs.Docs.type[lang],
        dataIndex: 'type',
        render: (type: string) => <>{DOC_TYPES.find((el) => el.type === type)?.name || ''}</>,
      },
      {
        title: langs.Docs.manager[lang],
        dataIndex: 'customer',
        render: (customer: any) => customer?.manager?.name || '',
      },
      {
        title: langs.Docs.status[lang],
        dataIndex: 'state',
        render: (state: string) => (
          <>
            {state === 'draft' && (
              <Tag color="rgba(0,0,0,.04)" style={{ border: 'rgba(0, 0, 0, 0.15) 1px solid' }}>
                <p style={{ color: 'rgba(0, 0, 0, 0.88)' }}>{langs.Docs.draft[lang]}</p>
              </Tag>
            )}
            {state === 'sent' && (
              <Tag color="#E6F4FF" style={{ border: '#91CAFF 1px solid' }}>
                <p style={{ color: '#1677FF' }}>{langs.Docs.sent[lang]}</p>
              </Tag>
            )}
            {state === 'signed' && (
              <Tag color="#F6FFED" style={{ border: '#B7EB8F 1px solid' }}>
                <p style={{ color: '#52C41A' }}>{langs.Docs.signed[lang]}</p>
              </Tag>
            )}
            {state === 'revoked' && (
              <Tag style={{ border: '#FFBB96 1px solid' }} color="#FFF2E8">
                <p style={{ color: '#FA541C' }}>{langs.Docs.revoked[lang]}</p>
              </Tag>
            )}
            {state === 'archived' && (
              <Tag style={{ border: '#FFBB96 1px solid' }} color="#FFF2E8">
                <p style={{ color: '#FA541C' }}>{langs.Clients.archive[lang]}</p>
              </Tag>
            )}
          </>
        ),
      },
      {
        title: langs.Docs.actions[lang],
        dataIndex: 'id',
        render: (id: number, row: any) => (
          <div className="flex gap-6">
            {(row.state === 'sent' || row.state === 'signed') && (
              <button onClick={() => openForPrint(id)}>
                <PrinterOutlined style={{ fontSize: 24, color: '#1677FF' }} />
              </button>
            )}
            {row.state === 'sent' && (
              <button onClick={() => revokeDoc(id)}>
                <StopOutlined style={{ fontSize: 24, color: '#FF4D4F' }} />
              </button>
            )}
            {row.state === 'draft' && (
              <>
                <button onClick={() => sentDocToSign(id)}>
                  <ArrowRightOutlined style={{ fontSize: 24, color: '#1677FF' }} />
                </button>
                <button onClick={() => deleteDocumentById(id)}>
                  <DeleteOutlined style={{ fontSize: 24, color: '#FF4D4F' }} />
                </button>
              </>
            )}
          </div>
        ),
      },
    ],
    [lang],
  );

  const handleSearch = useCallback(() => setSearchText(searchStr), [searchStr]);

  const handleRevokeVerification = async (id: number) => {
    const res = await clientRevokeVerification(id);
    if (res?.error) {
      toast(res.message, { type: 'error' });
      return;
    }
    toast(res?.message, { type: 'success' });
    queryClient.invalidateQueries();
  };

  const showSidebar = (key: string = '') => (
    <List
      key={key + (items?.length || 0)}
      data={items}
      activeIdx={activeIdx}
      onClick={(i: number) => setActiveIdx(i)}
      onSearch={handleSearch}
      value={searchStr}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchStr(e.target.value)}
      sortDirection={sortByName}
      setSortDirection={setSortByName}
      isLoading={isLoading}
    />
  );

  const openEditModal = () => setShowModal((prev) => ({ ...prev, edit: true }));

  const showImages = () =>
    ImagesNames.filter((field) => clientData?.[field])?.length ? (
      <div className="mt-[10px] flex gap-2">
        {ImagesNames.map((fieldName: any) =>
          clientData?.[fieldName] ? (
            <button
              className="w-[72px] h-[72px] rounded-[6px] bg-no-repeat bg-contain bg-center"
              style={{ backgroundImage: `url(${BASE_URL}${clientData[fieldName]})` }}
              key={fieldName}
              onClick={() => setShowImage(BASE_URL + clientData[fieldName])}
            />
          ) : null,
        )}
      </div>
    ) : (
      <p className="mt-3 opacity-45">{languages.docsError[lang]}</p>
    );

  const showStatus = () =>
    dataClient?.company ? (
      <Block className="w-[59%]">
        <div className="flex w-full justify-between mb-3">
          <h3>{languages.organizationData[lang]}</h3>
          <Button
            className="flex items-center"
            onClick={() => setShowModal((p) => ({ ...p, status: dataClient?.company }))}
          >
            <EditOutlined /> {COMPANY_TYPES_NAMES[dataClient?.company?.type] || ''}
          </Button>
        </div>
        <Cell name={languages.companyName[lang]} value={dataClient?.company?.name || '-'} />
        {dataClient?.company.type === 'physical' ? (
          <>
            <Cell name={langs.ModalStatus.passport[lang]} value={dataClient?.company?.passport} />
            <Cell name={langs.ModalStatus.phone[lang]} value={dataClient?.company?.phone} />
            <Cell name={langs.ModalStatus.email[lang]} value={dataClient?.company?.email} />
            <Cell name={langs.ModalStatus.position[lang]} value={dataClient?.company?.position} />
          </>
        ) : (
          <>
            <Cell name={langs.ModalStatus.inn[lang]} value={dataClient?.company?.inn} />
            <Cell name={langs.ModalStatus.kpp[lang]} value={dataClient?.company?.kpp} />
            <Cell name={langs.ModalStatus.ogrn[lang]} value={dataClient?.company?.ogrn} />
            <Cell name={langs.ModalStatus.director[lang]} value={dataClient?.company?.director} />
            <Cell name={langs.ModalStatus.address[lang]} value={dataClient?.company?.address} />
          </>
        )}
        {/* <div className="flex w-full justify-between">
          <h3 className="mt-5 mb-[10px]">{languages.requisites[lang]}</h3>
          <button style={{ fontSize: 24, color: '#1677FF' }}>
            <PlusOutlined />
          </button>
        </div> */}
        {dataAccounts?.items?.length > 0 && <p>{languages.contractAccount[lang]}</p>}
        {dataAccounts?.items?.length > 0 && (
          <ul className="w-full flex flex-col gap-2 mt-2">
            {dataAccounts.items.map((el: any, i: number) => (
              <li
                key={JSON.stringify(el)}
                className="flex w-full text-sm justify-between items-center"
              >
                <button className="flex" onClick={() => handleAccount(i)}>
                  <Radio checked={el.isDefaultAccount} />
                  {el.bankName}
                </button>
                <button>
                  <CloseOutlined style={{ opacity: 0.25 }} />
                </button>
              </li>
            ))}
          </ul>
        )}
      </Block>
    ) : (
      <Block className="w-[60%] h-full">
        <h3>{languages.organizationData[lang]}</h3>
        <p className="my-4 opacity-45">{languages.organizationNotAssigned[lang]}</p>
        <Button type="primary" onClick={() => setShowModal((prev) => ({ ...prev, status: true }))}>
          {languages.assign[lang]}
        </Button>
      </Block>
    );

  const showWarnings = () => (
    <div className="flex flex-col gap-6">
      {!dataClient?.company && (
        <BlueBox
          text={languages.assignOrganization[lang]}
          btnText={languages.assign[lang]}
          onClick={() => setShowModal((prev) => ({ ...prev, status: true }))}
        />
      )}
      {dataClient?.manager?.name ? (
        <div className="flex gap-2 items-center">
          <p>
            {langs.Docs.manager[lang]}: {dataClient.manager.name}
          </p>
          {(isUserAdmin(user?.roles) || isUserSupervisor(user?.roles)) && (
            <button onClick={() => setShowModal((prev) => ({ ...prev, manager: true }))}>
              <EditOutlined style={{ fontSize: 16 }} />
            </button>
          )}
        </div>
      ) : isUserAdmin(user?.roles) ? (
        <BlueBox
          text={languages.assignManager[lang]}
          btnText={languages.assign[lang]}
          onClick={() => setShowModal((prev) => ({ ...prev, manager: true }))}
        />
      ) : null}
    </div>
  );

  const requestStamp = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (clientData?.id) requestClientStamp(clientData.id);
  };

  const showPane = () =>
    loading ? (
      <Spinner fullW />
    ) : clientData ? (
      <div className="flex flex-col w-[78%]">
        <div className="flex justify-between items-start w-full">
          <h2 className="mb-6">{clientData.name}</h2>
          {/* <button>
            <MoreOutlined style={{ color: '#1677FF', fontSize: 20 }} />
          </button> */}
        </div>
        {showWarnings()}
        <Block className="mt-6 mb-5">
          <div className="flex w-full justify-between">
            <p className="font-semibold mb-[10px]">{clientData.name}</p>
            <button style={{ fontSize: 24, color: '#1677FF' }} onClick={openEditModal}>
              <EditOutlined />
            </button>
          </div>
          <div className="flex gap-8">
            <Row name={langs.showStatus.passport[lang]} value={clientData?.passport} />
            <Row name={langs.showStatus.phone[lang]} value={clientData?.phone} />
            <Row name={langs.showStatus.email[lang]} value={clientData?.email} />
            <Row name={langs.showStatus.position[lang]} value={clientData?.position} />
          </div>
        </Block>
        <div className="flex gap-5 w-full mb-6">
          {showStatus()}
          <Block className="w-[40%] h-full flex flex-col justify-between">
            <div>
              <div className="flex justify-between w-full items-center">
                <h3>{languages.documents[lang]}</h3>
                <button
                  disabled={!clientData.needStamp}
                  onClick={requestStamp}
                  className="text-[#1677FF]"
                >
                  {clientData.needStamp
                    ? languages.requestStamp[lang]
                    : languages.stampRequested[lang]}
                </button>
              </div>

              {showImages()}
            </div>
            {clientData?.verified && (
              <div className="w-full flex justify-end mt-6">
                <Popconfirm
                  title={languages.revokeVerification[lang]}
                  description={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: languages.revokeVerificationConfirm[lang],
                      }}
                    />
                  }
                  onConfirm={() => handleRevokeVerification(clientData.id)}
                  okText={langs.Docs.revoke[lang]}
                  cancelText={languages.cancel[lang]}
                  // description={<div className="my-2"> пропадёт из списка у клиента</div>}
                >
                  {/* <button>
                  <StopOutlined style={{ fontSize: 24, color: '#FF4D4F' }} />
                </button> */}
                  <Button>{languages.revokeVerification[lang]}</Button>
                </Popconfirm>
              </div>
            )}
          </Block>
        </div>
        <Block>
          <div className="flex w-full justify-between">
            <h3 className="whitespace-nowrap">{languages.documentsForSigning[lang]}</h3>
            <div>
              <Dropdown.Button
                menu={{
                  items: [
                    // { label: 'Из шаблона', key: 1, icon: <CopyOutlined /> },
                    { label: languages.sendFromUploaded[lang], key: 2, icon: <SaveOutlined /> },
                    {
                      label: languages.uploadPDF[lang],
                      key: 3,
                      icon: <DownloadOutlined />,
                    },
                  ],
                  onClick: (v) => {
                    if (v.key === '3' && clientData?.id) {
                      navigate(`${UPLOADS_PATH}/${clientData.id}`);
                    }
                  },
                }}
                onClick={(e) => console.log(e)}
                className="ml-auto"
              >
                {languages.sendDocument[lang]}
              </Dropdown.Button>
            </div>
          </div>

          <Table
            rowKey="id"
            // rowKey="name"
            loading={isLoadingCD}
            dataSource={dataClientDocs?.items || []}
            columns={columns}
            className="my-4"
            pagination={{ hideOnSinglePage: true, showSizeChanger: false }}
          />
        </Block>
      </div>
    ) : null;

  const tabsItems = [
    {
      key: 'active',
      label: languages.active[lang],
      children: (
        <div className="flex gap-6 w-full p-6 pl-1">
          {showSidebar('active')}
          {showPane()}
        </div>
      ),
    },
    {
      key: 'need-verification',
      label: (
        <div className="flex gap-2">
          {languages.needVerification[lang]}
          {activeTab === 'need-verification' && items?.length > 0 ? (
            <div className="text-[#FF4D4F]">{items.length}</div>
          ) : null}
        </div>
      ),
      children: (
        <div className="flex gap-6 w-full p-6 pl-1">
          {showSidebar('not-verified')}
          {loading ? (
            <Spinner fullW />
          ) : (
            <div className="w-full flex flex-col gap-6">
              {clientData && (
                <div className="flex flex-col">
                  <h2 className="mb-4">{clientData.name}</h2>
                  {showWarnings()}
                  <Block className={'mt-6'}>
                    <div className="flex w-full justify-between">
                      <p className="font-semibold mb-[10px]">{clientData.name}</p>
                      {(isUserAdmin(user?.roles) || isUserSupervisor(user?.roles)) && (
                        <Button
                          type="primary"
                          className="min-w-[185px]"
                          onClick={() => setShowModal((prev) => ({ ...prev, verification: true }))}
                        >
                          {langs.ModalVerification.buttons.verify[lang]}
                        </Button>
                      )}
                    </div>
                    <div className="flex w-full justify-between items-end">
                      <div className="flex gap-8">
                        <Row name={langs.showStatus.phone[lang]} value={clientData.phone} />
                        <Row name={langs.showStatus.email[lang]} value={clientData.email} />
                        <Row name={langs.showStatus.passport[lang]} value={clientData.passport} />
                        <Row name={langs.showStatus.position[lang]} value={clientData.position} />
                      </div>
                      {(isUserAdmin(user?.roles) || isUserSupervisor(user?.roles)) &&
                        !clientData?.deletedAt && (
                          <Popconfirm
                            title={languages.deleteClient[lang]}
                            description={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: languages.deleteClientConfirm[lang],
                                }}
                              />
                            }
                            onConfirm={() => {
                              if (!clientData?.id) return;
                              deleteClient(clientData.id)
                                .then((res: any) => {
                                  if (!res?.error) {
                                    toast(languages.clientDeleted[lang], { type: 'success' });
                                    setClientData(null);
                                    setActiveIdx(0);
                                    setActiveId(0);
                                    // refetchClient();
                                    refetchClients();
                                  } else {
                                    toast(res?.message || languages.errorDeleteClient[lang], {
                                      type: 'error',
                                    });
                                  }
                                })
                                .catch((error: any) => {
                                  toast(error?.message || languages.errorDeleteClient[lang], {
                                    type: 'error',
                                  });
                                });
                            }}
                            okText={languages.delete[lang]}
                            okButtonProps={{ danger: true }}
                            cancelText={languages.cancel[lang]}
                          >
                            <Button danger type="text">
                              {languages.delete[lang]}
                            </Button>
                          </Popconfirm>
                        )}
                    </div>
                  </Block>
                </div>
              )}
              {clientData && (
                <div className="flex gap-6">
                  {showStatus()}

                  <Block className="h-full w-full">
                    <div className="flex justify-between w-full items-center">
                      <h3>{languages.documents[lang]}</h3>
                      {/* <p className="text-[#1677FF]">Запросить печать</p> */}
                    </div>

                    {showImages()}
                    {clientData?.verified && (
                      <div className="w-full flex justify-end">
                        <Popconfirm
                          title={languages.revokeVerification[lang]}
                          description={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: languages.revokeVerificationConfirm[lang],
                              }}
                            />
                          }
                          onConfirm={() => handleRevokeVerification(clientData.id)}
                          okText={langs.Doc.revoke[lang]}
                          cancelText={langs.Doc.cancel[lang]}
                        >
                          <Button>{languages.revokeVerification[lang]}</Button>
                        </Popconfirm>
                      </div>
                    )}
                  </Block>
                </div>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      key: 'archive',
      label: languages.archive[lang],
      children: (
        <div className="flex gap-6 w-full p-6 pl-1">
          {showSidebar('archived')}
          {showPane()}
        </div>
      ),
    },
  ];

  const reset = () => {
    setSearchStr('');
    setSearchText('');
    setActiveIdx(0);
  };

  useEffect(() => {
    reset();
  }, [activeTab]);

  useEffect(() => {
    const data = items?.[activeIdx] || null;
    setClientData(data);
    sessionStorage.removeItem('values');
  }, [activeIdx, activeTab, items]);

  useEffect(() => {
    document.body.style.overflow = Object.values(showModal).some(Boolean) ? 'hidden' : 'unset';
  }, [showModal]);

  useEffect(() => {
    if (!!isShowCreateOrgModal && selectedStatus === 0) {
      const valuesSS = sessionStorage.getItem('values');
      if (valuesSS) {
        const values = JSON.parse(valuesSS);
        formOrg.setFieldsValue(values);
      }
    }
  }, [selectedStatus, isShowCreateOrgModal]);

  return (
    <>
      <div className="my-4 mx-[50px] flex flex-col">
        <div className="w-full flex justify-between mb-4">
          <h1 className="text-[20px]">{languages.title[lang]}</h1>

          <Button
            className="bg-white h-10 flex items-center text-base"
            onClick={() => navigate(DB_PATH)}
          >
            <FileTextOutlined />
            {languages.database[lang]}
          </Button>
        </div>

        <Tabs
          defaultActiveKey="1"
          activeKey={activeTab.toString()}
          onChange={(v) => setActiveTab(v)}
          indicator={{ size: 0 }}
          items={tabsItems}
        />
      </div>
      {showModal.edit && (
        <ModalEditClient
          open={showModal.edit}
          data={clientData}
          onEdit={onEdit}
          onClose={() => {
            refetchClients();
            refetchClient();
            setShowModal((prev) => ({ ...prev, edit: false }));
          }}
        />
      )}
      {showModal.manager && (
        <ModalManager
          open={showModal.manager}
          data={clientData}
          dataManagers={dataManagers}
          onClose={() => {
            refetchClients();
            refetchClient();
            setShowModal((prev) => ({ ...prev, manager: false }));
          }}
        />
      )}
      {showModal.status && (
        <ModalStatus
          open={showModal.status}
          data={clientData}
          dataCompanies={dataCompanies}
          dataAccounts={dataAccounts}
          company={company}
          setCompany={setCompany}
          setIsShowCreateOrgModal={setIsShowCreateOrgModal}
          setIsShowCreateAccModal={setIsShowCreateAccModal}
          refetch={{ refetchClient, refetchClients, refetchAccounts, refetchCompanies }}
          onClose={() => {
            queryClient.invalidateQueries();
            setIsShowCreateAccModal(false);
            setShowModal((prev) => ({ ...prev, status: false }));
          }}
        />
      )}
      {showModal.verification && (
        <ModalVerification
          step={step}
          setStep={setStep}
          onEdit={onEdit}
          onVerify={onVerify}
          open={showModal.verification}
          data={clientData}
          dataClient={dataClient}
          dataCompanies={dataCompanies}
          dataAccounts={dataAccounts}
          dataManagers={dataManagers}
          company={company}
          setCompany={setCompany}
          setShowImage={setShowImage}
          setIsShowCreateOrgModal={setIsShowCreateOrgModal}
          setIsShowCreateAccModal={setIsShowCreateAccModal}
          refetch={{ refetchClient, refetchClients, refetchAccounts, refetchCompanies }}
          onClose={() => {
            setStep(0);
            queryClient.invalidateQueries();
            // setClientData(null);
            // setActiveIdx(-1);
            setShowModal((prev) => ({ ...prev, verification: false }));
          }}
        />
      )}
      <Modal
        centered
        open={!!showImage}
        onCancel={() => setShowImage('')}
        footer={null}
        className="!w-[80vw]"
      >
        <div
          className="w-full min-h-[90vh] bg-no-repeat bg-contain bg-center"
          style={{ backgroundImage: `url(${showImage})` }}
        />
      </Modal>

      <Modal
        centered
        onCancel={() => {
          formOrg.resetFields();
          setIsShowCreateOrgModal(false);
          setSelectedStatus(0);
        }}
        open={!!isShowCreateOrgModal}
        footer={null}
        maskClosable={false}
        getContainer={false}
        title={languages.addOrganization[lang]}
      >
        <Radio.Group
          onChange={(e) => setSelectedStatus(e.target.value)}
          value={selectedStatus}
          className="flex items-center gap-4 my-4 w-full"
        >
          {COMPANY_TYPES.map((type, index) => (
            <Radio key={index} value={index} className="!min-w-fit">
              {COMPANY_TYPES_NAMES[type]}
            </Radio>
          ))}
        </Radio.Group>
        <Form
          layout="horizontal"
          form={formOrg}
          className="flex flex-col mt-4"
          name="form"
          initialValues={{ remember: true }}
          onFinish={onFinishAddCompany}
        >
          {selectedStatus === 0 ? (
            <>
              <Form.Item label={langs.ModalEditClient.name[lang]} name="name" rules={RULES.NAME}>
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label={langs.showStatus.passport[lang]}
                name="passport"
                rules={RULES.PASSPORT}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item label={langs.showStatus.phone[lang]} name="phone" rules={RULES.PHONE}>
                <Input allowClear />
              </Form.Item>
              <Form.Item label={langs.showStatus.email[lang]} name="email" rules={RULES.EMAIL}>
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label={langs.showStatus.position[lang]}
                name="position"
                rules={RULES.POSITION}
              >
                <Input allowClear />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                labelCol={{ style: { minWidth: 170 } }}
                label={languages.companyName[lang]}
                name="name"
                rules={[{ required: true, message: languages.enterCompany[lang] }]}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                labelCol={{ style: { minWidth: 170 } }}
                label={languages.inn[lang]}
                name="inn"
                rules={RULES.INN}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                labelCol={{ style: { minWidth: 170 } }}
                label={langs.showStatus.kpp[lang]}
                name="kpp"
                rules={RULES.KPP}
              >
                <Input allowClear />
              </Form.Item>

              <Form.Item
                labelCol={{ style: { minWidth: 170 } }}
                label={langs.showStatus.ogrn[lang]}
                name="ogrn"
                rules={RULES.OGRN}
              >
                <Input allowClear />
              </Form.Item>

              <Form.Item
                labelCol={{ style: { minWidth: 170 } }}
                label={langs.showStatus.director[lang]}
                name="director"
                rules={RULES.DIRECTOR}
              >
                <Input allowClear />
              </Form.Item>

              <Form.Item
                labelCol={{ style: { minWidth: 170 } }}
                label={languages.address[lang]}
                name="address"
                rules={[{ required: true, message: languages.enterAddress[lang] }]}
              >
                <TextArea allowClear />
              </Form.Item>
            </>
          )}

          <Form.Item className="ml-auto">
            <Button type="primary" htmlType="submit">
              {languages.save[lang]}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        centered
        onCancel={() => {
          formAcc.resetFields();
          setIsShowCreateAccModal(false);
        }}
        open={!!isShowCreateAccModal}
        footer={null}
        maskClosable={false}
        getContainer={false}
        title={languages.addRequisites[lang]}
      >
        <Form
          layout="horizontal"
          form={formAcc}
          className="flex flex-col mt-4"
          name="form"
          initialValues={{ remember: true }}
          onFinish={onFinishAddAcc}
        >
          <Form.Item
            labelCol={{ style: { minWidth: 170 } }}
            label={languages.bankName[lang]}
            name="bankName"
            rules={[{ required: true, message: languages.enterBank[lang] }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            labelCol={{ style: { minWidth: 170 } }}
            label={languages.bik[lang]}
            name="bankBik"
            rules={RULES.BIK}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            labelCol={{ style: { minWidth: 170 } }}
            label={languages.currentAccount[lang]}
            name="currentAccount"
            rules={RULES.ACCOUNT}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            labelCol={{ style: { minWidth: 170 } }}
            label={languages.corrAccount[lang]}
            name="corrAccount"
            rules={RULES.COR_ACCOUNT}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            labelCol={{ style: { minWidth: 170 } }}
            label={languages.address[lang]}
            name="address"
            rules={[{ required: true, message: 'Пожалуйста, введите юридический адрес' }]}
          >
            <TextArea allowClear />
          </Form.Item>

          <Form.Item className="ml-auto">
            <Button type="primary" htmlType="submit">
              {languages.save[lang]}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});

export default Clients;
