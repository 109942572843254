import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Popconfirm } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import Table from '../../components/Table';
import { FormatDate, isUserAdmin, isUserSupervisor } from '../../utils/helpers';
import { createUser, deleteUser, editUser, getUsers } from '../../utils/httpServices/global';
import EmployeeModal from './EmployeeModal';
import globalController from '../../mobx/GlobalController';
import { ReadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { GET_ROLES_FN } from '../../utils/consts';
import { ROLES_PATH } from '../../utils/routes_consts';
import { langs } from '../../utils/lang';

const languages = langs.Employees;

const Employees = observer(() => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = globalController.getUser();
  const lang = globalController.getLang();
  const ROLES = GET_ROLES_FN(lang);
  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading, error, data, refetch } = useQuery(
    ['users', currentPage],
    () => getUsers(currentPage),
    { staleTime: 300 * 1000 },
  );
  const items = data?.items || [];
  const pagination = data?.pagination || {};

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCreate = (values: any) => {
    createUser(values)
      .then((res) => {
        if (res.error) {
          console.log(error);
          // globalController.handleError(res);
        } else {
          toast.success(languages.added[lang]);
          refetch();
          closeModal();
        }
      })
      .catch((error: any) => console.log(error));
  };

  const handleEdit = async (data: any) => {
    if (typeof data !== 'object') {
      toast.error(languages.errorEdit[lang], { type: 'error' });
      return;
    }
    const { id, ...otherData } = data;
    editUser(id, otherData)
      .then((res) => {
        if (res?.error) {
          console.log(error);
        } else {
          refetch();
          closeModal();
        }
      })
      .catch((error: any) => console.log(error));
  };

  const handleDelete = useCallback(
    (id: number) => {
      deleteUser(id)
        .then((res) => {
          if (res.error) {
            globalController.handleError(res);
          } else {
            refetch();
          }
        })
        .catch((error: any) => {
          globalController.handleError(error);
        });
    },
    [refetch],
  );

  const columns = useMemo(
    () => [
      {
        title: languages.columns.name[lang],
        dataIndex: 'name',
      },
      {
        title: languages.columns.email[lang],
        dataIndex: 'email',
      },
      {
        title: languages.columns.role[lang],
        dataIndex: 'roles',
        render: (roles: any[]) => ROLES.find((el) => el.name === roles?.[0])?.title,
      },
      {
        title: languages.columns.created_at[lang],
        dataIndex: 'createdAt',
        render: (date: string) => FormatDate(date),
      },
      {
        title: languages.columns.actions[lang],
        dataIndex: 'id',
        render: (id: number, row: any) => (
          <div
            className="gap-6"
            style={{ display: user?.id && user?.id !== row.id ? 'flex' : 'none' }}
          >
            {(isUserAdmin(user?.roles) || isUserSupervisor(user?.roles)) && (
              <button onClick={() => setIsModalOpen(row)}>
                <EditOutlined
                  style={{ fontSize: 24, color: '#1677FF' }}
                  title={languages.edit_tooltip[lang]}
                />
              </button>
            )}
            {isUserAdmin(user?.roles) && (
              <Popconfirm
                title={languages.delete_confirm_title[lang]}
                description={
                  <div className="max-w-[24rem]">{languages.delete_confirm_description[lang]}</div>
                }
                onConfirm={() => handleDelete(id)}
                okText={languages.delete_button[lang]}
                cancelText={languages.cancel_button[lang]}
              >
                <button>
                  <DeleteOutlined
                    style={{ fontSize: 24, color: '#FF4D4F' }}
                    title={languages.delete_confirm_title[lang]}
                  />
                </button>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ],
    [handleDelete, user, lang],
  );

  return (
    <div className="my-4 mx-[50px] flex flex-col">
      <div className="w-full flex justify-between">
        <div>
          <h1 className="text-[20px]">{languages.title[lang]}</h1>
          <p
            className="text-[#1677FF] inline-flex gap-2 items-center cursor-pointer"
            role="link"
            tabIndex={0}
            onClick={() => navigate(ROLES_PATH)}
          >
            <ReadOutlined />
            {languages.roles_link[lang]}
          </p>
        </div>
        {isUserAdmin(user?.roles) && (
          <Button type="primary" className="w-[196px] h-10" onClick={openModal}>
            {languages.add_button[lang]}
          </Button>
        )}
      </div>
      <Table
        key={JSON.stringify(user)}
        loading={isLoading}
        rowKey="id"
        dataSource={items}
        columns={columns}
        className="my-4"
        pagination={{
          hideOnSinglePage: true,
          current: currentPage,
          pageSize: pagination.perPage || 20,
          total: pagination.totalCount || 0,
          showSizeChanger: false,
        }}
        isLoading={isLoading}
        onChange={(e: any) => setCurrentPage(e.current)}
      />
      <EmployeeModal
        open={isModalOpen}
        onCancel={closeModal}
        onCreate={handleCreate}
        onEdit={handleEdit}
      />
    </div>
  );
});

export default Employees;
