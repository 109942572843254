import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckOutlined, CloseOutlined, HomeOutlined } from '@ant-design/icons';
import { Block } from '../../components/PageElements';
import Table from '../../components/Table';
import { EMPLOYEES_PATH } from '../../utils/routes_consts';
import { observer } from 'mobx-react-lite';
import globalController from '../../mobx/GlobalController';
import { langs } from '../../utils/lang';

const languages = langs.Roles;
const languages1 = langs.rolesData;

const renderBool = (el: boolean) =>
  el ? (
    <CheckOutlined style={{ color: '#389E0D', fontSize: 16 }} />
  ) : (
    <CloseOutlined style={{ fontSize: 16 }} />
  );

const Roles = observer(() => {
  const lang = globalController.getLang();
  const navigate = useNavigate();

  const roles = useMemo(
    () => [
      {
        category: languages1.categories.clientManagement[lang],
        name: languages1.rights.assignClients[lang],
        superadmin: true,
        admin: true,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.clientManagement[lang],
        name: languages1.rights.verifyClient[lang],
        superadmin: true,
        admin: true,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.clientManagement[lang],
        name: languages1.rights.viewClients[lang],
        superadmin: true,
        admin: true,
        user: true,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.clientManagement[lang],
        name: languages1.rights.createClient[lang],
        superadmin: true,
        admin: true,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.clientManagement[lang],
        name: languages1.rights.editClient[lang],
        superadmin: true,
        admin: true,
        user: true,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.clientManagement[lang],
        name: languages1.rights.deleteClient[lang],
        superadmin: true,
        admin: true,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.clientManagement[lang],
        name: languages1.rights.archiveClient[lang],
        superadmin: true,
        admin: true,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.contractManagement[lang],
        name: languages1.rights.createContract[lang],
        superadmin: true,
        admin: true,
        user: true,
        financial: true,
        client: false,
      },
      {
        category: languages1.categories.contractManagement[lang],
        name: languages1.rights.editContract[lang],
        superadmin: true,
        admin: true,
        user: true,
        financial: true,
        client: false,
      },
      {
        category: languages1.categories.contractManagement[lang],
        name: languages1.rights.viewContracts[lang],
        superadmin: true,
        admin: true,
        user: true,
        financial: true,
        client: false,
      },
      {
        category: languages1.categories.contractManagement[lang],
        name: languages1.rights.revokeDocuments[lang],
        superadmin: true,
        admin: true,
        user: true,
        financial: true,
        client: false,
      },
      {
        category: languages1.categories.employeeManagement[lang],
        name: languages1.rights.assignRole[lang],
        superadmin: true,
        admin: false,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.employeeManagement[lang],
        name: languages1.rights.viewEmployees[lang],
        superadmin: true,
        admin: true,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.employeeManagement[lang],
        name: languages1.rights.deleteEmployee[lang],
        superadmin: true,
        admin: false,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.employeeManagement[lang],
        name: languages1.rights.editEmployee[lang],
        superadmin: true,
        admin: true,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.employeeManagement[lang],
        name: languages1.rights.createEmployee[lang],
        superadmin: true,
        admin: false,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.whatsappMessaging[lang],
        name: languages1.rights.sendMessageToClients[lang],
        superadmin: true,
        admin: true,
        user: true,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.whatsappMessaging[lang],
        name: languages1.rights.sendMessageToAll[lang],
        superadmin: true,
        admin: true,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.settingsManagement[lang],
        name: languages1.rights.addCompanyCard[lang],
        superadmin: true,
        admin: false,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.settingsManagement[lang],
        name: languages1.rights.editCompanyCard[lang],
        superadmin: true,
        admin: true,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.settingsManagement[lang],
        name: languages1.rights.deleteCompanyCard[lang],
        superadmin: true,
        admin: false,
        user: false,
        financial: false,
        client: false,
      },
      {
        category: languages1.categories.settingsManagement[lang],
        name: languages1.rights.uploadTerms[lang],
        superadmin: true,
        admin: false,
        user: false,
        financial: false,
        client: false,
      },
    ],
    [lang],
  );

  const columns = useMemo(
    () => [
      { title: languages.category[lang], dataIndex: 'category' },
      { title: languages.right[lang], dataIndex: 'name' },
      {
        title: languages.superadmin[lang],
        dataIndex: 'superadmin',
        render: (el: boolean) => renderBool(el),
      },
      {
        title: languages.admin[lang],
        dataIndex: 'admin',
        render: (el: boolean) => renderBool(el),
      },
      {
        title: languages.financial[lang],
        dataIndex: 'financial',
        render: (el: boolean) => renderBool(el),
      },
      {
        title: languages.user[lang],
        dataIndex: 'user',
        render: (el: boolean) => renderBool(el),
      },
      {
        title: languages.client[lang],
        dataIndex: 'client',
        render: (el: boolean) => renderBool(el),
      },
    ],
    [lang],
  );

  return (
    <div className="my-4 mx-[50px] flex flex-col">
      <button
        onClick={() => navigate(EMPLOYEES_PATH)}
        className="opacity-45 w-fit flex gap-1 items-center"
      >
        <HomeOutlined />
        {languages.back[lang]}
      </button>
      <h2 className="mt-2 mb-5">{languages.title[lang]}</h2>
      <Block className="bg-white">
        <Table
          rowKey="name"
          dataSource={roles}
          columns={columns}
          pagination={{ hideOnSinglePage: true, pageSize: 999, showSizeChanger: false }}
        />
      </Block>
    </div>
  );
});

export default Roles;
