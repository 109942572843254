import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { RULES_FN } from '../../utils/consts';
import globalController from '../../mobx/GlobalController';
import { observer } from 'mobx-react-lite';
import { langs } from '../../utils/lang';

const languages = langs.ModalEditClient;

const ModalEditClient = observer(({ open, data, onEdit, onClose }: any) => {
  const lang = globalController.getLang();
  const RULES = RULES_FN(lang);
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        if (onEdit) onEdit(data.id, values);
      })
      .catch((errorInfo) => console.log('Failed:', errorInfo));
  };

  useEffect(() => {
    if (data) {
      const { name, passport, phone, email, position } = data;
      form.setFieldsValue({ name, passport, phone, email, position });
    }
  }, [data]);

  return (
    <Modal centered onCancel={onClose} open={open} footer={null}>
      <Form
        layout="vertical"
        form={form}
        className="flex flex-col"
        name="form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onValuesChange={handleValuesChange}
      >
        <h2 className="mb-5">{languages.title[lang]}</h2>
        <Form.Item label={languages.name[lang]} name="name" rules={RULES.NAME}>
          <Input allowClear />
        </Form.Item>
        <Form.Item label={languages.passport[lang]} name="passport" rules={RULES.PASSPORT}>
          <Input allowClear />
        </Form.Item>
        <Form.Item label={languages.phone[lang]} name="phone" rules={RULES.PHONE}>
          <Input allowClear />
        </Form.Item>
        <Form.Item label={languages.email[lang]} name="email" rules={RULES.EMAIL}>
          <Input allowClear />
        </Form.Item>
        <Form.Item label={languages.position[lang]} name="position" rules={RULES.POSITION}>
          <Input allowClear />
        </Form.Item>
        <Form.Item className="ml-auto">
          <Button type="primary" htmlType="submit">
            {languages.save[lang]}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default ModalEditClient;
