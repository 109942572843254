import { Button, Divider, Dropdown, Form, Input, Modal, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { ImagesNames } from './imagesNames';
import { BASE_URL, RULES_FN } from '../../utils/consts';
import { FullscreenOutlined } from '@ant-design/icons';
import { assignManagerToClient, requestClientStamp } from '../../utils/httpServices/global';
import { toast } from 'react-toastify';
import ModalStatus from './ModalStatus';
import { observer } from 'mobx-react-lite';
import globalController from '../../mobx/GlobalController';
import { langs } from '../../utils/lang';

const { Step } = Steps;

const languages = langs.ModalVerification;

const ModalVerification = observer(
  ({
    open,
    data,
    dataClient,
    onEdit,
    onVerify,
    onClose,
    setShowImage,
    dataManagers,
    dataCompanies,
    dataAccounts,
    refetch,
    setIsShowCreateOrgModal,
    setIsShowCreateAccModal,
    company,
    setCompany,
    step = 0,
    setStep,
  }: any) => {
    const lang = globalController.getLang();
    const RULES = RULES_FN(lang);
    const { refetchClient, refetchClients, refetchAccounts, refetchCompanies } = refetch;

    const [form] = Form.useForm();
    const [selectedImgIdx, setSelectedImgIdx] = useState(0);
    const [manager, setManager] = useState<any>(null);
    const [stampRequested, setStampRequested] = useState(false);

    const handleSelectManager = (val: any) => {
      const mng = (dataManagers?.items || []).find((el: any) => +el.id === +val.key);
      setManager(mng || null);
      if (mng) {
        assignManagerToClient(data?.id, { managerId: mng?.id || 0 }).then((res) => {
          if (res?.manager?.id && res?.manager?.name) {
            toast(languages.managerSelection.managerAssigned[lang](res.manager.name), {
              type: 'success',
            });
          }
          return res;
        });
      }
    };

    const onFinish = (values: any) => {
      form
        .validateFields()
        .then(() => {
          if (onEdit) {
            onEdit(data.id, values).then((res: any) => {
              if (res && !res?.error) {
                setStep((prev: number) => prev + 1);
                if (values) form.setFieldsValue({ ...values });
              }
            });
          }
        })
        .catch((error) => console.log('error:', error));
    };

    const onFinish3 = () => {
      onVerify(data.id).then((res: any) => {
        if (res && !res.error) {
          onClose();
          return res;
        }
      });
    };

    useEffect(() => {
      const mng = (dataManagers?.items || []).find(
        (el: any) => el.name === dataClient?.manager?.name,
      );
      setManager(mng || null);
    }, []);

    useEffect(() => {
      if (data && step === 0) {
        form.setFieldsValue({ ...data });
        const { name, phone, email, passport, position } = data;
        sessionStorage.setItem(
          'values',
          JSON.stringify({ name, phone, email, passport, position }),
        );
      }
    }, [data, step]);

    const requestStamp = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      requestClientStamp(data.id).then((response) => {
        if (response?.error) {
          toast(
            response.error?.response?.data?.message || languages.errors.requestStampError[lang],
            { type: 'error' },
          );
        } else setStampRequested(true);
      });
    };

    return (
      <Modal
        centered
        maskClosable={false}
        getContainer={false}
        onCancel={onClose}
        footer={null}
        open={open}
        className="min-w-[50vw]"
      >
        <h2 className="mb-5">{languages.title[lang]}</h2>
        <Steps current={step}>
          <Step title={languages.steps.step1[lang]} />
          <Step title={languages.steps.step2[lang]} />
          <Step title={languages.steps.step3[lang]} />
        </Steps>
        {step === 0 && (
          <Form
            layout="vertical"
            form={form}
            className="flex flex-col"
            name="form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onValuesChange={(_, v) => sessionStorage.setItem('values', JSON.stringify(v))}
          >
            <div className="flex gap-4 mt-4">
              <div className="flex flex-col w-[40%]">
                <Form.Item label="ФИО" name="name" rules={RULES.NAME}>
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  label={langs.showStatus.passport[lang]}
                  name="passport"
                  rules={RULES.PASSPORT}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item label={langs.showStatus.phone[lang]} name="phone" rules={RULES.PHONE}>
                  <Input allowClear />
                </Form.Item>
                <Form.Item label={langs.showStatus.email[lang]} name="email" rules={RULES.EMAIL}>
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  label={langs.showStatus.position[lang]}
                  name="position"
                  rules={RULES.POSITION}
                >
                  <Input allowClear />
                </Form.Item>
              </div>
              <div className="flex flex-col w-[60%] gap-4">
                <div
                  className="h-2/3 w-full bg-[#F1F1F1] rounded-[9px] bg-center bg-contain bg-no-repeat relative"
                  style={{
                    backgroundImage: `url(${BASE_URL}${data[ImagesNames[selectedImgIdx]]})`,
                  }}
                >
                  <Button
                    htmlType="button"
                    className="bg-white h-8 w-8 flex items-center justify-center absolute top-2 left-2"
                    onClick={() => setShowImage(BASE_URL + data[ImagesNames[selectedImgIdx]])}
                  >
                    <FullscreenOutlined />
                  </Button>
                </div>
                <div className="w-full h-[72px] flex gap-2 overflow-auto max-w-[100%]">
                  {ImagesNames.map((fieldName, i) =>
                    data?.[fieldName] ? (
                      <button
                        type="button"
                        className="w-[72px] h-[72px] rounded-[6px] bg-no-repeat bg-contain bg-center"
                        style={{
                          backgroundImage: `url(${BASE_URL}${data[fieldName]})`,
                          border: `3px solid ${i === selectedImgIdx ? '#1677FF' : 'transparent'}`,
                        }}
                        key={fieldName}
                        onClick={() => setSelectedImgIdx(i)}
                      />
                    ) : null,
                  )}
                </div>
                {data?.needStamp && (
                  <button
                    disabled={stampRequested}
                    onClick={requestStamp}
                    className="text-[#1677FF]"
                  >
                    {!stampRequested
                      ? languages.requestStamp[lang]
                      : languages.stampRequested[lang]}
                  </button>
                )}
              </div>
            </div>
            <Form.Item className="ml-auto">
              <Button onClick={onClose} className="mr-4">
                {languages.buttons.cancel[lang]}
              </Button>
              <Button type="primary" htmlType="submit">
                {languages.buttons.next[lang]}
              </Button>
            </Form.Item>
          </Form>
        )}
        {step === 1 && (
          <div className="flex flex-col w-full mt-4">
            <ModalStatus
              isSmall
              open={step === 1}
              data={data}
              dataCompanies={dataCompanies}
              dataAccounts={dataAccounts}
              company={company}
              setCompany={setCompany}
              setIsShowCreateOrgModal={setIsShowCreateOrgModal}
              setIsShowCreateAccModal={setIsShowCreateAccModal}
              setStep={setStep}
              refetch={{ refetchClient, refetchClients, refetchAccounts, refetchCompanies }}
              onClose={() => setIsShowCreateAccModal(false)}
            />
          </div>
        )}
        {step === 2 && (
          <div className="w-full min-h-[30vh] flex flex-col justify-between">
            <div className="w-full">
              <h3 className="my-5">{languages.managerSelection.title[lang]}</h3>
              <div className="w-1/2 mb-6">
                <Dropdown
                  menu={{
                    items: (dataManagers?.items || []).map((el: any) => ({
                      key: el.id,
                      label: el.name,
                    })),
                    onClick: handleSelectManager,
                  }}
                  autoFocus
                  trigger={['click']}
                >
                  <a
                    tabIndex={0}
                    onClick={(e) => e.preventDefault()}
                    className="rounded-[6px] border px-4 py-1"
                    style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}
                  >
                    <div className="inline-flex items-center justify-between w-full">
                      {manager?.name || languages.managerSelection.listPlaceholder[lang]}
                      <DownOutlined />
                    </div>
                  </a>
                </Dropdown>
              </div>
            </div>
            <div className="flex flex-col items-end mt-auto w-full">
              <Divider />
              <div className="flex gap-4">
                <Button onClick={() => setStep(1)}>{languages.buttons.back[lang]}</Button>
                <Button type="primary" onClick={onFinish3} disabled={!manager}>
                  {languages.buttons.verify[lang]}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  },
);

export default ModalVerification;
