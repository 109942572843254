import { langs } from './lang';

export type Lang = 'en' | 'ru';

export const APP_VERSION = 0.34;

export const RQ_OPT = { staleTime: 300_000 };

export const btnProps = {
  role: 'button',
  tabIndex: 0,
  onKeyDown: (e: any) => {
    if (e.key === 'Enter') {
      e.target.click();
    }
  },
};

export const emailRegex =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]{1,}(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9-]{2,}\.)+[a-zA-Z]{2,}$/;

export const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{6,30}$/;

let isProd = process.env.NODE_ENV === 'production';
if (window.location.href.includes('dev')) {
  isProd = false;
}

export const BASE_URL = isProd ? 'https://signatorypro.com' : 'https://dev.signatorypro.com';
export const URL_API = `${BASE_URL}/crm/v1`;
export const MD_URL_API = `${BASE_URL}/api/v1`;

export const optionsItemsPerPage = [10, 25, 50, 100, 200];

export const DEBOUNCE_TIME_MS = 900;

export const GET_ROLES_FN = (lang: Lang) => [
  { title: langs.Roles.superadmin[lang], name: 'ROLE_ADMIN' },
  { title: langs.Roles.admin[lang], name: 'ROLE_SUPERVISOR' },
  { title: langs.Roles.financial[lang], name: 'ROLE_FINANCIAL_MANAGER' },
  { title: langs.Roles.user[lang], name: 'ROLE_MANAGER' },
];

export const DAYS = [
  { en: 'mon', ru: 'Пн' },
  { en: 'tue', ru: 'Вт' },
  { en: 'wed', ru: 'Ср' },
  { en: 'thu', ru: 'Чт' },
  { en: 'fri', ru: 'Пт' },
  { en: 'sat', ru: 'Сб' },
  { en: 'sun', ru: 'Вс' },
];

export const COMPANY_TYPES = ['physical', 'individual', 'legal_entity'];
export const COMPANY_TYPES_NAMES_FN = (lang: Lang) => ({
  [COMPANY_TYPES[0]]: langs.showStatus.individual[lang],
  [COMPANY_TYPES[1]]: langs.showStatus.soleProprietor[lang],
  [COMPANY_TYPES[2]]: langs.showStatus.legalEntity[lang],
});

export const languages = [
  { key: 'ru', label: 'РУССКИЙ' },
  { key: 'en', label: 'ENGLISH' },
];

export const selectLangText = {
  en: 'Select language',
  ru: 'Выберите язык',
};

export const MAX_PDF_SIZE_MB = 10;

export const A4_WIDTH_MM = 210;
export const A4_HEIGHT_MM = 297;
export const MIN_STAMP_SIZE = 50;

export const DOC_TYPES_FN = (lang: Lang) => [
  { type: 'contract', name: langs.DocTypes.contract[lang] },
  { type: 'additional_agreement', name: langs.DocTypes.additional_agreement[lang] },
  { type: 'annex_to_contract', name: langs.DocTypes.annex_to_contract[lang] },
  { type: 'power_of_attorney', name: langs.DocTypes.power_of_attorney[lang] },
  { type: 'procedural_document', name: langs.DocTypes.procedural_document[lang] },
  { type: 'claim', name: langs.DocTypes.claim[lang] },
  { type: 'letter', name: langs.DocTypes.letter[lang] },
];

export const RULES_FN = (lang: Lang) => ({
  PASSWORD: [{ required: true, message: langs.Rules.PASSWORD[lang] }],
  PASSPORT: [
    { required: true, message: langs.Rules.PASSPORT[0][lang] },
    { max: 20, message: langs.Rules.PASSPORT[1][lang] },
    { pattern: /^[a-zA-Z0-9]*$/, message: langs.Rules.PASSPORT[2][lang] },
  ],
  PHONE: [
    { required: true, message: langs.Rules.PHONE[0][lang] },
    { max: 15, message: langs.Rules.PHONE[1][lang] },
  ],
  EMAIL: [
    { required: true, message: langs.Rules.EMAIL[0][lang] },
    {
      validator: (rule: any, value: string) => {
        if (!emailRegex.test(value)) {
          return Promise.reject(langs.Helpers.invalidEmailFormat[lang]);
        }
        return Promise.resolve();
      },
    },
    { max: 50, message: langs.Rules.EMAIL[1][lang] },
  ],
  NAME: [
    { required: true, message: langs.Rules.NAME[0][lang] },
    { min: 1, max: 32, message: langs.Rules.NAME[1][lang] },
    { pattern: /^[a-zA-Zа-яА-ЯЁё\s-]*$/, message: langs.Rules.NAME[2][lang] },
  ],
  INN: [
    { required: true, message: langs.Rules.INN[0][lang] },
    { pattern: /^[a-zA-Z0-9]{9,14}$/, message: langs.Rules.INN[1][lang] },
  ],
  KPP: [
    { len: 9, message: langs.Rules.KPP[1][lang] },
    { pattern: /^\d{9}$/, message: langs.Rules.KPP[2][lang] },
  ],
  OGRN: [
    { required: true, message: langs.Rules.OGRN[0][lang] },
    { len: 13, message: langs.Rules.OGRN[1][lang] },
    { pattern: /^\d{13}$/, message: langs.Rules.OGRN[2][lang] },
  ],
  DIRECTOR: [
    { required: true, message: langs.Rules.DIRECTOR[0][lang] },
    { min: 1, max: 32, message: langs.Rules.DIRECTOR[1][lang] },
    { pattern: /^[a-zA-Zа-яА-ЯЁё\s-]*$/, message: langs.Rules.DIRECTOR[2][lang] },
  ],
  POSITION: [{ max: 32, message: langs.Rules.POSITION[lang] }],
  BIK: [{ required: true, message: langs.Rules.BIK[0][lang] }],
  ACCOUNT: [{ required: true, message: langs.Rules.ACCOUNT[0][lang] }],
  COR_ACCOUNT: [{ required: true, message: langs.Rules.COR_ACCOUNT[0][lang] }],
  NUMBER: [{ required: true, pattern: /^\d{1,3}$/, message: langs.Rules.NUMBER[lang] }],
});
