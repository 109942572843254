import React, { useCallback, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import clsx from 'clsx';

import Header from '../Header';
import routes from '../../routes';
import globalController from '../../mobx/GlobalController';
import { APP_VERSION } from '../../utils/consts';
import { getCurrentUser, getProfile } from '../../utils/httpServices/global';
import { LOGIN_PATH } from '../../utils/routes_consts';
import localeRU from 'antd/lib/locale/ru_RU';
import localeEN from 'antd/lib/locale/en_GB';
import { ConfigProvider } from 'antd';
import { observer } from 'mobx-react-lite';

const Layout = observer(({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const user = globalController.getUser();
  const lang = globalController.getLang();
  const location = useLocation();
  const { pathname, search } = location;
  const isPrint = search?.includes('print');
  const isLoginPage = pathname === LOGIN_PATH || pathname.includes('password');

  const updateUser = useCallback(
    (data = {}) => {
      getProfile().then((res) => {
        if (res && !res.error) {
          const udata = user || data;
          const userData = { ...udata, ...res };
          sessionStorage.setItem('user', JSON.stringify(userData));
          globalController.setUser(userData);
        }
      });
    },
    [user],
  );

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (routes.filter((el) => !el.withNoToken).find((el) => el.path.includes(pathname)) && !token) {
      navigate(LOGIN_PATH, { replace: true });
    }
    if (!user && token) {
      getCurrentUser()
        .then((res) => {
          if (res?.currentUser) {
            sessionStorage.setItem('user', JSON.stringify(res.currentUser));
            globalController.setUser(res.currentUser);
          }
          return res?.currentUser || res;
        })
        .then((data) => {
          updateUser(data);
        });
    }
  }, [navigate, pathname, updateUser, user]);

  useEffect(() => {
    if (isLoginPage) return;
    const interval = setInterval(() => {
      const userData = globalController.getUser();
      updateUser(userData);
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  const href = window.location.href;
  const isShowVer = href.includes('dev') || href.includes('uat') || href.includes('localhost');

  return (
    <ConfigProvider locale={lang === 'ru' ? localeRU : localeEN}>
      {isShowVer && (
        <div className="absolute top-0 right-[2px] opacity-50 text-white z-[300] text-[8px]">
          v{APP_VERSION}
        </div>
      )}
      <Header />
      <div
        className={clsx(
          'flex flex-col w-full min-h-[100dvh] overflow-auto bg-[#F5F5F5]',
          !isPrint && 'pt-16',
        )}
      >
        {children}
      </div>
    </ConfigProvider>
  );
});

export default Layout;
